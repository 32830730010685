import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Steps from "../steps/Steps";
import BeneficiaryDetails from "./BeneficiaryDetails";
import GroupDetails from "./group-details/GroupDetails";
import { variants } from "../../shared/variants";

function DetailScreens() {
  const screen = sessionStorage.getItem("travel_type")!;
  const detail_screen = sessionStorage.getItem("details-screen")!;
  const [currentScreen, setCurrentScreen] = useState<string>();
  useEffect(() => {
    if (detail_screen) {
      setCurrentScreen(detail_screen);
    } else {
      if (screen == "Group" || screen == "Family") {
        setCurrentScreen("Group-screen");
      }
    }
  });

  return (
    <motion.div variants={variants} initial="enter" animate="center">
      {currentScreen === "Group-screen" && (
        <GroupDetails setCurrentScreen={setCurrentScreen} />
      )}
      {currentScreen === "Beneficiary-screen" && (
        <BeneficiaryDetails setCurrentScreen={setCurrentScreen} />
      )}
    </motion.div>
  );
}

export default DetailScreens;
