import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Steps from "../steps/Steps";
import BeneficiaryInformation from "./beneficiary-information/BeneficiaryInformation";
import CovertDetails from "./cover-details/CovertDetails";
import PersonalInformation from "./personal-information/PersonalInformation";
import { variants } from "../../shared/variants";

function SummaryInformation() {
  const [currentScreen, setCurrentScreen] = useState<string>("cover-details");
  const summaryConfirmationScreen = sessionStorage.getItem(
    "summary-confirmation-screen"
  );
  useEffect(() => {
    if (summaryConfirmationScreen) {
      setCurrentScreen(summaryConfirmationScreen);
    } else {
      setCurrentScreen("cover-details");
    }
  });
  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      <Steps name="summary" />
      {currentScreen === "cover-details" && (
        <CovertDetails setCurrentScreen={setCurrentScreen} />
      )}
      {currentScreen === "personal-information" && (
        <PersonalInformation setCurrentScreen={setCurrentScreen} />
      )}
      {currentScreen === "beneficiary-information" && (
        <BeneficiaryInformation setCurrentScreen={setCurrentScreen} />
      )}
    </motion.div>
  );
}

export default SummaryInformation;
