import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useWizard } from "react-use-wizard";
import { makePostRequest } from "../../../../services/apiHandler";
import Loader from "../../../../shared/loader/Loader";
import {NumericFormat} from "react-number-format";
import * as GrIcons from "react-icons/gr";
import { variants } from "../../../../shared/variants";
import { useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";


const mpesaImage = require("../../../../../assets/images/mpesa.png");
const kenya = require("../../../../../assets/images/kenya.png");


function Mpesa({ setCurrentScreen }: any) {

  const profileSelector = useSelector((state: any) => state.profile.profile);
  const { goToStep } = useWizard()
  const savedQuote = JSON.parse(sessionStorage.getItem("quote")!)
  const [paymInfo, setPaymInfo] = useState({
    phoneNumber: profileSelector?.phone ? profileSelector.phone : "",
  });

  const [loading, setLoading] = useState(false);
  const SKEY = sessionStorage.getItem("SKEY") || "";
  const quote_amount = Number(sessionStorage.getItem("quote_amount"));
  const quote_id = sessionStorage.getItem("quote_id");

  const [processing, setProcessing] = useState(false);

  const paymentOption = sessionStorage.getItem("paymentOption");
  const planTenure = Number(sessionStorage.getItem("planTenure"));
  
  const mixpanel = useMixpanel()

  useEffect(()=> {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Payment-mpesa",
      });
    }
  }, [mixpanel])

  const proceed = () => {
    const onValidatePhoneNumber = (phoneNumber: any) => {
      const regex = /^(\+254|0)[1-9]\d{8}$/.test(phoneNumber);
      return regex;
    };
    if (!onValidatePhoneNumber(paymInfo.phoneNumber)) {
      toast.warning("Enter valid phone number!");
      document.getElementById("phoneNumber")?.focus();
      return;
    }

    processPayment();
  };
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPaymInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getStagePaymentPayload = (): any => {
    let body: any = {
      bank: "N/A",
      cover: "travel",
      customPerUnitRepaymentAmount: 0,
      isDownPayment: false,
      kyosk: false,
      _id: quote_id,
    };
    if (paymentOption == "pp") {
      const p: any = sessionStorage.getItem("caculatedPlan") || {};
      const plan = JSON.parse(p);
      body.bank = "TRUSTGRO";
      body["paymentPlanDetails"] = plan;
      body["plan"] = planTenure;
      return body;
    }else {
      return body;
    }
  };

  const processPayment = async () => {
    setLoading(true);
    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_IPF_URL}/stagePayment`,
      getStagePaymentPayload()
    );

    if (result.Status === 200) {
      let stageResponse = result.Payload;
      sessionStorage.setItem(
        "stagePaymentResponse",
        JSON.stringify(result.Payload)
      );

      const res: IApiResponse = await makePostRequest(
        `${process.env.REACT_APP_MPESA_URL}/nextpayment`,
        {
          quoteid: quote_id,
        },
        {
          SKEY: SKEY,
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
          apiKey: `${process.env.REACT_APP_MODULE_ID}`,
        }
      );

      setProcessing(false);

      if (res.Status === 200) {
        let nextPaymentResponse = res.Payload;
        if (paymentOption == "renewal" || paymentOption == "pp") {
          const p: any = sessionStorage.getItem("caculatedPlan") || {};
          const plan = JSON.parse(p);
          sessionStorage.setItem("ppPayable", plan.downPaymentPayable);
        }
        sessionStorage.setItem(
          "nextPaymentResponse",
          JSON.stringify(res.Payload)
        );
        initiatePayment({
          amount: nextPaymentResponse?.amount,
          schedule: stageResponse?.schedule,
          paymentid: nextPaymentResponse._id,
        });
      } else if (res.Status === 541 || res.Status === 543) {
        toast.warning("Session expired please login again!");
        goToStep(3)
      } else {
        toast.error("Something went wrong please try again!");
        setLoading(false)
      }
    } else {
      toast.error("Something went wrong please try again!");
      setProcessing(false);
    }
  };

  const initiatePayment = async ({ amount, schedule, paymentid }: any) => {
    let body = {
      _id: quote_id,
      amount: amount,
      broker: "mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007",
      currency: "KES",
      id: schedule[0].id,
      internalTenant: 1,
      mobileNumber: paymInfo.phoneNumber,
      paymentid: paymentid,
      redeemBimaPoints: 0.0,
      ref: "travel",
      referralCode: "N/A",
      tenantUUID: "mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007",
      transactionDescription: "domestic",
      transactionRef: quote_id,
      transactionType: "MPESA",
    };
    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_MPESA_URL}/initiatepayment`,
      body,
      {
        SKEY: SKEY,
        moduleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (result.Status === 200) {
      setLoading(false);
      let data = result.Payload;
      sessionStorage.setItem("mpesaPaybillNo", data.mpesaPaybillNo);
      sessionStorage.setItem("mtekPaymentRef", data.mtekPaymentRef);
      sessionStorage.setItem("paymResponse", JSON.stringify(data));
      sessionStorage.setItem("payment-screen","payment-confirmation")
      setCurrentScreen("payment-confirmation")
    } else if (result.Status === 541 || result.Status == 543) {
      toast.warning("Session expired please login again");
      goToStep(3)
    } else {
      setLoading(false);
      toast.error(
        "Payment could not be processed, our sales team will contact you shortly!"
      );
    }
  };
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <br />
        <div className="paym-confirmation-container px-2">
          <div className="paym-confirmation-wapper">
            <div className="paym-text">
            We’re almost there...{" "}<br />
             Please Pay <span className="paym-text-amount">
                <NumericFormat
                  value={savedQuote?.amount.toFixed(2)}
                  displayType={"text"}
                  prefix={"Ksh. "}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />{" "}
              </span>{" "}
              via M-pesa mobile money option.
            </div>
            <div className="mt-5 mb-5">
              <img src={mpesaImage} alt="Mpesa" />
            </div>
            <p className="pym-p">
              Enter your phone number below. <span style={{fontFamily:"Roboto-Regular"}}>You will receive an M-Pesa pop up
              to complete the transaction.</span>
            </p>
            <div className="row">
              <div className="col-md-4">
              <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="py-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="phoneNumber">
                  Phone Number
                </label>
                <br />
                <img
                  className="kenyan-flag"
                  src={kenya}
                  alt="kenya flag"
                />
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  className="mpesa-number"
                  type="text"
                  onChange={handleInputChange}
                  value={paymInfo.phoneNumber}
                  placeholder="+254729000000"
                ></input>
              </motion.div>
            </div>
              </div>
            </div>
            
            {loading && <Loader />}
            {!loading && (
              <div className="mt-5" onClick={() => proceed()}>
                <button className="pointer-btn">Confirm Payment</button>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem("payment-screen", "payment-method");
                  setCurrentScreen("payment-method");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default Mpesa;
