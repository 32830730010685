import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import * as GrIcons from "react-icons/gr";
import { toast } from "react-toastify";
import { makePostRequest } from "../../../services/apiHandler";
import { stepActions } from "../../../store/Steps";
import { useDispatch } from "react-redux";
import Loader from "../../../shared/loader/Loader";
import { variants } from "../../../shared/variants";
import { useMixpanel } from "react-mixpanel-browser";
import { getSessionData, setSessionData } from "../../../services/app.services";
import { DataStorageKey } from "../../../enums/appNums";

function BeneficiaryInformation({ setCurrentScreen }: any) {
  const { previousStep, nextStep, goToStep } = useWizard();
  const dispatch = useDispatch();
  const u: any = sessionStorage.getItem("userInfo");
  const uDocs: any = sessionStorage.getItem("userDocs");

  const SKEY = sessionStorage.getItem("SKEY") || "";

  const email = sessionStorage.getItem("email") || "";
  const q: any = sessionStorage.getItem("quote");

  const userInfo = JSON.parse(u);
  const quote = JSON.parse(q);

  const bInfo: any = sessionStorage.getItem("beneficiaryInfo");
  const userDocs = JSON.parse(uDocs);
  const beneficiaryInfo = JSON.parse(bInfo);

  const [loading, setLoading] = useState(false);
  const travel_type = sessionStorage.getItem("travel_type")!;
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Beneficiary-summary",
      });
    }
  }, [mixpanel]);

  const getQuestionsPayloadBody = () => {
    let body: any = {
      ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
      moreQuestions: {},
    };
    if (travel_type == "Group" || travel_type == "Family") {
      let g = sessionStorage.getItem("group_members")!;
      const gMembers: any[] = JSON.parse(g);
      if (gMembers.length > 0) {
        body.moreQuestions.dependents = [...gMembers];
      }
    }
    return body;
  };
  const saveDetails = () => {
    saveQuestions();
  };

  const saveQuestions = async () => {
    setLoading(true);
    try {
      let questions: IApiResponse = await makePostRequest(
        `${process.env.REACT_APP_CORE_API_URL}/savequestions`,
        {
          ...getQuestionsPayloadBody(),
        },
        {
          SKEY: SKEY,
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
          apiKey: `${process.env.REACT_APP_MODULE_ID}`,
        }
      );
      if (questions.Status == 200) {
        let data = {
          ...quote,
          questions_id: questions?.Payload?._id,
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        };
        try {
          const quoteResponseResult: IApiResponse = await makePostRequest(
            `${process.env.REACT_APP_TRAVEL_API_URL}/savequote`,
            {
              ...data,
            },
            {
              SKEY: SKEY,
              ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
              apiKey: `${process.env.REACT_APP_MODULE_ID}`,
            }
          );
          if (quoteResponseResult.Status == 200) {
            try {
              let updateQuestionsResponse: IApiResponse = await makePostRequest(
                `${process.env.REACT_APP_CORE_API_URL}/updatequestions`,
                {
                  _id: quoteResponseResult.Payload?.questions_id,
                  qanda: {
                    client: {
                      email: email,
                      ...userInfo,
                      ...beneficiaryInfo,
                      ...userDocs,
                    },
                    startDate: sessionStorage.getItem("startDate"),
                    endDate: sessionStorage.getItem("endDate"),
                  },
                },
                {
                  SKEY: SKEY,
                  ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
                  apiKey: `${process.env.REACT_APP_MODULE_ID}`,
                }
              );
              if (updateQuestionsResponse.Status == 200) {
                sessionStorage.setItem(
                  "quote_id",
                  quoteResponseResult.Payload._id
                );
                setSessionData(DataStorageKey, {
                  quote: quoteResponseResult.Payload,
                });
                sessionStorage.setItem(
                  "quote",
                  JSON.stringify(quoteResponseResult.Payload)
                );
                sessionStorage.setItem("payment-screen", "promotions");
                nextStep();
                setLoading(false);
              } else if (
                updateQuestionsResponse.Status === 543 ||
                updateQuestionsResponse.Status === 401
              ) {
                setLoading(false);
                toast.warning("Session expired please login again!");
                goToStep(3);
              } else {
                setLoading(false);
                toast.error(quoteResponseResult.Payload);
              }
            } catch (error: any) {
              setLoading(false);
              toast.error("Error updating questions:", error.toString());
            }
          } else if (
            quoteResponseResult.Status === 543 ||
            quoteResponseResult.Status === 401
          ) {
            setLoading(false);
            toast.warning("Session expired please login again!");
            goToStep(3);
          } else {
            setLoading(false);
            toast.error(quoteResponseResult.Payload);
          }
        } catch (error: any) {
          setLoading(false);
          toast.error("Error saving quote:", error?.toString());
        }
      } else if (questions.Status === 543 || questions.Status === 401) {
        setLoading(false);
        toast.warning("Session expired please login again!");
        goToStep(3);
      } else {
        setLoading(false);
        toast.error(questions.Payload);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error("Error saving questions:", error?.toString());
    }
  };
  
  const convertNamesToUpperCase = (name: string): string => {
    return name
      ?.split(" ")
      ?.map((name) => name?.charAt(0)?.toUpperCase() + name?.slice(1))
      ?.join(" ");
  };

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
      className="steps-conatiner2"
    >
      <div className="personal-information">
        <div className="reg-title text-center">
          Your <span style={{ color: "#30C2DB" }}>beneficiary details...</span>
        </div>
        <br />
        <div className="confirmation-container">
          <div className="confirmation-wapper">
            <div className="conf-sect-group mb-2">
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Full Name</span>
                <span className="conf-keys">{beneficiaryInfo?.nokName}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Relationship</span>
                <span className="conf-keys">
                  {convertNamesToUpperCase(beneficiaryInfo?.nokRelationship)}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Phone Number</span>
                <span className="conf-keys">{beneficiaryInfo?.nokPhoneNo}</span>
              </div>
            </div>

            <div className="mt-5 mb-5"></div>
          </div>
          <div className="d-flex justify-content-between align-items-center ms-3 me-2 mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem(
                    "summary-confirmation-screen",
                    "personal-information"
                  );
                  setCurrentScreen("personal-information");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            {loading && <Loader />}
            {!loading && (
              <div onClick={() => saveDetails()}>
                <button className="next-btn">
                  {" "}
                  <span>
                    Proceed{" "}
                    <span className="GrIcon ms-3">
                      <GrIcons.GrFormNextLink />
                    </span>
                  </span>{" "}
                </button>
              </div>
            )}
          </div>
        </div>
        <br />
        <br />
      </div>
    </motion.div>
  );
}

export default BeneficiaryInformation;
