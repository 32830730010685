import { createSlice } from "@reduxjs/toolkit";

let group = JSON.parse(sessionStorage.getItem("groupData")!)
  ? JSON.parse(sessionStorage.getItem("groupData")!)
  : {};

  
const GroupQuotesPayloadSlice = createSlice({
  initialState: group,
  name: "groupPayload",
  reducers: {
    setGroupPayload(state, action) {
      state.group = action?.payload;
      sessionStorage.setItem("groupData", JSON.stringify({ group: state?.group }));
      return state
    },
  },
});

export const getGroupPayloadActions = GroupQuotesPayloadSlice.actions;

export default GroupQuotesPayloadSlice;
