import { createSlice } from "@reduxjs/toolkit";

let individual = JSON.parse(sessionStorage.getItem("profile")!)
  ? JSON.parse(sessionStorage.getItem("profile")!)
  : {};

const ProfileSlice = createSlice({
  name: "profile",
  initialState: individual,
  reducers: {
    setUserProfile(state, action) {
      state.profile = action.payload;
      sessionStorage.setItem(
        "profile",
        JSON.stringify({ profile: state?.profile })
      );
      return state;
    },
  },
});

export const profileActions = ProfileSlice.actions;

export default ProfileSlice;
