import React, { useEffect, useState } from 'react'
import Login from './Login';
import Reset from './Reset';
import Signup from './SignUp';
import { useMixpanel } from 'react-mixpanel-browser';


function Auth() {
    const [currentScreen, setCurrentScreen] = useState("login");
    const mixpanel = useMixpanel();
    useEffect(() => {
      if (mixpanel.config.token) {
        mixpanel.track("PageView", {
          pageName: "Travel|Authentication",
        });
      }
    }, [mixpanel]);
  return (
    <>
        {currentScreen === 'login' && <Login setCurrentScreen={setCurrentScreen}/>}
        {currentScreen === 'signup' && <Signup setCurrentScreen={setCurrentScreen}/>}
        {currentScreen === 'reset' && <Reset setCurrentScreen={setCurrentScreen}/>}
    </>
);
}

export default Auth