import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { makePostRequest } from "../../services/apiHandler";
import Loader from "../../shared/loader/Loader";

import mtekLogo  from "../../../assets/images/mtek-logo.svg"


const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function ListClaims({ setCurrentScreen }: any) {
  const SKEY = sessionStorage.getItem("SKEY") || "";
  const feduid = sessionStorage.getItem("uid")!;
  const navigate = useNavigate();
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);

  const getQuotes = async () => {
    setLoading(true);
    const res: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/adminListClaims`,
      {
        feduid: feduid,
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (res.Status === 200) {
      setLoading(false);
      setClaims(res.Payload);
    } else {
      setLoading(false);
      if (res.Status === 541 || res.Status === 401 || res.Status === 543) {
        setLoading(false);
        sessionStorage.setItem("fromMyPol", "true");
        toast.warning("Session expired please login again!");
        navigate("/login");
      } else {
        toast.error("Something went wrong. Please try again!");
      }
    }
  };

  useEffect(() => {
    if (!SKEY) {
      sessionStorage.setItem("fromMyPol", "true");
      navigate("/login");
      return;
    } else {
      getQuotes();
    }
  }, []);

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travel icon" />
        </div>
        <div className="reg-title text-center">
          <span className="span-title">Travel Claims</span>{" "}
        </div>
        <div className="text-center mt-4 mb-1">
          <span className="bs-link-n" onClick={() => navigate("/policies")}>
            My policies
          </span>
          <span
            className="bs-link-n text-primary text-bold"
            onClick={() => navigate("/claims")}
          >
            My claims
          </span>
        </div>
        <br />
        <div className="px-2">
          <div className="my-policies-container">
            {loading && <Loader />}
            {!loading && claims.length < 1 && (
              <h1 className="text-center mt-2">You have no claims yet!</h1>
            )}
            {claims.length > 0 &&
              claims
                .sort((a: any, b: any) => {
                  return b._timestamp - a._timestamp;
                })
                .map((item: any, index: any) => {
                  return (
                    <div key={index} className="my-pol-card mb-4">
                      <div className="my-pol-det-container">
                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Policy Holder</div>
                          <div className="my-pol-tit">
                            {item?.policyHolder || "N/A"}
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Policy Number</div>
                          <div className="my-pol-tit">
                            {item?.policyNumber || "N/A"}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Underwriter</div>
                          <div className="my-pol-tit">{item?.insurerName}</div>
                        </div>
                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title ms-3">Status</div>
                          <div
                            className={
                              item.status == "Submitted"
                                ? "claim-status submitted"
                                : item.status == "progress"
                                ? "claim-status progress"
                                : "claim-status"
                            }
                          >
                            {item.status}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 my-pol-cta-container">
                        <div className="w-100">
                          <div className="my-pol-line"></div>
                        </div>
                        <div className=""></div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default ListClaims;
