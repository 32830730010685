import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as GrIcons from "react-icons/gr";
import InputFile from "../../../shared/file-uploads/InputFile";
import { useWizard } from "react-use-wizard";
import { stepActions } from "../../../store/Steps";
import { useDispatch, useSelector } from "react-redux";
import { variants } from "../../../shared/variants";
import { profileActions } from "../../../store/UserProfileData";

import backIcon from "../../../../assets/images/back.svg";
import frontIcon from "../../../../assets/images/front.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import { britamInsurer, travellingReason } from "../../../enums/appNums";
import { useMixpanel } from "react-mixpanel-browser";

export function InsuredDocs({ setCurrentScreen }: any) {
  const { nextStep, goToStep } = useWizard();
  const mixpanel = useMixpanel()
  const quote = JSON.parse(sessionStorage.getItem("quote")!);

  const insurer = quote?.insurer;

  const profileSelector = useSelector((state: any) => state.profile.profile);

  const [userDocs, setuserDocs] = useState({
    idNumber: profileSelector.idNumber ? profileSelector?.idNumber : "",
    idPhotoBack: "",
    idPhotoFront: "",
    kraPin: profileSelector?.kraPin ? profileSelector?.kraPin : "",
    kraPhoto: "",
    passportNumber: profileSelector?.passportNumber
      ? profileSelector?.passportNumber
      : "",
    passportPhoto: "",
    schoolProof: "",
  });

  const getTravellingOption = (): string => {
    let plan = quote?.policy?.plan;
    if (plan == "DOMESTIC") {
      return "town";
    }
    return "country";
  };

  const [showId, onShowId] = useState<boolean>(
    getTravellingOption() == "town" ? true : false
  );
  const [showPass, onShowPass] = useState<boolean>(
    getTravellingOption() == "country" ? true : false
  );

  const dispatch = useDispatch();

  const upDateFormBeingDisplayedToPassForm = () => {
    onShowPass(true);
    onShowId(false);
  };
  const upDateFormBeingDisplayedToIDForm = () => {
    onShowPass(false);
    onShowId(true);
  };

  const screen = sessionStorage.getItem("travel_type")!;
  const saveDetails = () => {
    if (showId) {
      if (!userDocs.idNumber) {
        toast.warning("Enter ID number");
        return;
      }
      if (!userDocs.idPhotoFront) {
        toast.warning("Upload front ID!");
        return;
      }
      if (!userDocs.idPhotoBack) {
        toast.warning("Upload back ID!");
        return;
      }
    }

    if (showPass) {
      if (!userDocs.passportNumber) {
        toast.warning("Enter Passport number");
        return;
      }
      if (!userDocs.passportPhoto) {
        toast.warning("Upload Passport Document!");
        return;
      }
      if (insurer == britamInsurer) {
        if (!userDocs.kraPin) {
          toast.warning("Enter KRA Pin!");
          document.getElementById("kraPin")?.focus();
          return;
        }
      }
    }

    if (quote?.policy?.reason == travellingReason) {
      if (!userDocs.schoolProof) {
        toast.warning("Upload proof of schooling Document!");
        return;
      }
    }

    if (insurer == britamInsurer) {
      if (!onValidateKraPin(userDocs.kraPin)) {
        toast.warning("Invalid Kra pin");
        return;
      }

      if (!userDocs.kraPhoto) {
        toast.warning("Upload KRA Document!");
        return;
      }
    }

    let userKycData: any = { ...userDocs };
    delete userKycData?.formOfIdentification;
    if (insurer !== britamInsurer) {
      delete userKycData?.kraPin;
      delete userKycData?.kraPhoto;
    }
    if (quote?.policy?.reason !== travellingReason) {
      delete userKycData?.schoolProof;
    }
    if (showPass) {
      delete userKycData?.idNumber;
      delete userKycData?.idPhotoFront;
      delete userKycData?.idPhotoBack;
    } else {
      delete userKycData?.passportNumber;
      delete userKycData?.passportPhoto;
    }

    sessionStorage.setItem("userDocs", JSON.stringify(userKycData));
    screen != "Group" &&
      sessionStorage.setItem("details-screen", "Beneficiary-screen");
    screen == "Group" ||
      (screen == "Family" &&
        sessionStorage.setItem("details-screen", "Group-screen"));
    sessionStorage.removeItem("docs-current-screen");

    if (dispatch(stepActions.onAddStep())) {
      nextStep();
      let userPrfile = {
        ...profileSelector,
        idNumber: userDocs?.idNumber,
        kraPin: userDocs?.kraPin,
        passportNumber: userDocs?.passportNumber,
      };
      dispatch(profileActions.setUserProfile(userPrfile));
    }
  };
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setuserDocs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onValidateKraPin = (pinNumber: any) => {
    const regex = /^[AP][0-9]{9}[A-Z]$/;
    return regex.test(pinNumber);
  };

  const updateDocs = (name: any, url: any) => {
    setuserDocs((prevState) => ({
      ...prevState,
      [name]: url,
    }));
  };

  useEffect(() => {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Insurer-documents",
      });
    }
    setCurrentScreen("insurer-docs");
  }, [mixpanel]);

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div className="ky-inputs-container">
          <div className="reg-title text-left">
            Please submit the <span className="span-title">documents</span>{" "}
            below...
          </div>
          <br />
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="startDate">
                Form of Identification
              </label>
              <br />
              <div className="d-flex">
                {getTravellingOption() == "town" && (
                  <div>
                    <input
                      id="ID"
                      name="formOfIdentification"
                      type="radio"
                      onChange={handleInputChange}
                      value="id"
                      checked={showId}
                      onClick={upDateFormBeingDisplayedToIDForm}
                    ></input>
                    <label className="ml-4" htmlFor="ID">
                      ID
                    </label>
                  </div>
                )}
                <div className="ml-4">
                  <input
                    id="passport"
                    name="formOfIdentification"
                    type="radio"
                    onChange={handleInputChange}
                    value="pass"
                    checked={showPass}
                    onClick={upDateFormBeingDisplayedToPassForm}
                  ></input>
                  <label className="ml-4" htmlFor="passport">
                    Passport
                  </label>
                </div>
              </div>
            </motion.div>
          </div>
          {showId && (
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-md-4">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="idNumber">
                      National ID{" "}
                    </label>
                    <br />
                    <input
                      id="idNumber"
                      name="idNumber"
                      type="text"
                      onChange={handleInputChange}
                      value={userDocs.idNumber}
                      placeholder="Type ID number"
                    ></input>
                  </motion.div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="postal">
                      Upload ID Document
                    </label>
                    <br />
                    <div className="id-uploads-container">
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"id-front"}
                        allowedFileExtensions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon={frontIcon}
                        updateDocs={updateDocs}
                        name="idPhotoFront"
                      />
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"id-back"}
                        allowedFileExtensions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon={backIcon}
                        updateDocs={updateDocs}
                        name="idPhotoBack"
                      />
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          )}
          {showPass && (
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-md-4">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="passportNumber"
                    >
                      Passport Number{" "}
                    </label>
                    <br />
                    <input
                      id="passportNumber"
                      name="passportNumber"
                      type="text"
                      onChange={handleInputChange}
                      value={userDocs.passportNumber}
                      placeholder="Type Passport number"
                    ></input>
                  </motion.div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="postal">
                      Upload Passport Document
                    </label>
                    <br />
                    <div className="id-uploads-container">
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"passport-document"}
                        allowedFileExtensions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon={uploadIcon}
                        updateDocs={updateDocs}
                        name="passportPhoto"
                      />
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          )}
          {quote?.policy?.reason == travellingReason && (
            <>
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container mt-4"
                >
                  <label className="text-left-label mb-2" htmlFor="startDate">
                    Proof Of Schooling
                  </label>
                  <div className="id-uploads-container">
                    <InputFile
                      fileLocation={"profile"}
                      aFileName={"schoolProof"}
                      allowedFileExtensions={["png", "jpg", "jpeg", "pdf"]}
                      refIcon={uploadIcon}
                      updateDocs={updateDocs}
                      name="schoolProof"
                    />
                  </div>
                  <br />
                </motion.div>
              </div>
            </>
          )}
          {insurer === britamInsurer && (
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-md-4">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="kraPin">
                      KRA Pin
                    </label>
                    <br />
                    <input
                      id="kraPin"
                      name="kraPin"
                      type="text"
                      onChange={handleInputChange}
                      value={userDocs.kraPin}
                      placeholder="Type KRA pin"
                    ></input>
                  </motion.div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="postal">
                      Upload KRA Document
                    </label>
                    <br />
                    <div className="id-uploads-kraPhot">
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"kra-document"}
                        allowedFileExtensions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon={uploadIcon}
                        updateDocs={updateDocs}
                        name="kraPhoto"
                      />
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          )}
          {/* {loading && <Loader />} */}
          <div className="insurer-footer d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem(
                    "docs-current-screen",
                    "insurer-details"
                  );
                  setCurrentScreen("insurer-details");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div className="" onClick={() => saveDetails()}>
              <button className="next-btn">PROCEED</button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}
