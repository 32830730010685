import { createSlice } from "@reduxjs/toolkit";

let currentScreen = sessionStorage.getItem("travel_payload_current_screen")
  ? sessionStorage.getItem("travel_payload_current_screen")
  : "individual";

const TravelPayloadCurrentScreenSplice = createSlice({
  name: "travel-current-screen",
  initialState: currentScreen,
  reducers: {
    setCurrentScreen(state, action) {
      state = action?.payload;
      sessionStorage.setItem("travel_payload_current_screen", String(state));
      return state;
    },
  },
});

export const travelCurrentScreenActions =
  TravelPayloadCurrentScreenSplice.actions;

export default TravelPayloadCurrentScreenSplice;
