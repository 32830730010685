import React, { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { motion } from "framer-motion";
import "../../travel.css";
import Steps from "../steps/Steps";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";
import { useNavigate } from "react-router-dom";
import { variants } from "../../shared/variants";
import { useMixpanel } from "react-mixpanel-browser";

function GetStarted() {
  const mixpanel = useMixpanel();
  const onGetStartedValue =
    JSON.parse(sessionStorage.getItem("onGetStarted")!) === true;
  const [onGetStarted, onShowGetStartedPage] =
    useState<boolean>(onGetStartedValue);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { nextStep, goToStep } = useWizard();

  const handleOutboundClick = () => {
    sessionStorage.setItem("travel_option", "outbound");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };

  const handleInboundClick = () => {
    sessionStorage.setItem("travel_option", "inbound");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };

  const handleLocalClick = () => {
    sessionStorage.setItem("travel_option", "town");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };
  const getStarted = () => {
    onShowGetStartedPage(true);
    sessionStorage.setItem("onGetStarted", "true");
  };

  const location = window.location.search;
  const generatedQuoteID = new URLSearchParams(location).get(
    "generatedQuoteId"
  );
  const getquotesFromWhatsspp = new URLSearchParams(location).get("getquotes");
  const groupid = new URLSearchParams(location).get("groupid");
  const familyid = new URLSearchParams(location).get("familyid");
  const mxDistinctId = new URLSearchParams(location).get("mxDistinctId");

  const uploadWhattspGroupMembers = () => {
    if (groupid) {
      goToStep(1);
    }
  };

  const uploadWhattspFamilyMembers = () => {
    if (familyid) {
      goToStep(1);
    }
  };

  const goToGetQuotes = () => {
    if (generatedQuoteID || getquotesFromWhatsspp) {
      goToStep(2);
    }
  };

  const getMixPanelDistinctId = () => {
    if (mxDistinctId) {
      sessionStorage.setItem("mxDistinctId", mxDistinctId);
    } else {
      sessionStorage.setItem("mxDistinctId", mixpanel.get_distinct_id());
    }
  };

  useEffect(() => {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Get-started",
      });
    }
    getMixPanelDistinctId()
    goToGetQuotes();
    uploadWhattspGroupMembers();
    uploadWhattspFamilyMembers();
  }, [
    generatedQuoteID,
    getquotesFromWhatsspp,
    groupid,
    familyid,
    onGetStarted,
    mixpanel,
    mxDistinctId
  ]);

  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="get-started" />
      {onGetStarted && (
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          className="get-started-buttons"
        >
          <div className="travel-options">
            <p className="travel-instruction">
              What would you like to be covered for?
            </p>
            <div className="travel-buttons">
              <button
                className="travel-button me-5"
                onClick={handleInboundClick}
              >
                Inbound Travel
              </button>
              <button
                className="travel-button me-5"
                onClick={handleOutboundClick}
              >
                Outbound Travel
              </button>
              <button className="travel-button" onClick={handleLocalClick}>
                Local Travel
              </button>
            </div>
          </div>
        </motion.div>
      )}
      {!onGetStarted && (
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          className="get-started"
        >
          <span className="simple">It’s simple & fast!</span>
          <span className="simple simple-vehicle-details">
            Provide travel details, get your quote, pay & receive your{" "}
            <span className="simple-sticker">policy instantly!</span>
          </span>
          <button className="button-get-started" onClick={getStarted}>
            Get Started
          </button>
          <a className="view-policies" onClick={() => navigate("/policies")}>
            View Policies
          </a>
        </motion.div>
      )}
    </motion.div>
  );
}

export default GetStarted;
