import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Steps from '../steps/Steps'
import InsurerDetails from './insurer-details/InsurerDetails';
import { InsuredDocs } from './insurer-docs/InsuredDocs';
import { variants } from '../../shared/variants';

function DetailsOfInsured() {
    const [currentScreen,setCurrentScreen] =useState<string>()
    const docsCurrentScreen = sessionStorage.getItem("docs-current-screen")
    useEffect(()=> {
      if(docsCurrentScreen){
        setCurrentScreen(docsCurrentScreen)
      }else{
        setCurrentScreen("insurer-details")
      }
    }, [])
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="insured-details" />
      {currentScreen === "insurer-details" && <InsurerDetails setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "insurer-docs" && <InsuredDocs setCurrentScreen={setCurrentScreen}/>}
      </motion.div>
  )
}

export default DetailsOfInsured