import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import * as GrIcons from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import mtekLogo  from "../../../assets/images/mtek-logo.svg"

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};



function BaggageAdditionalInformation({ setCurrentScreen }: any) {
  const navigate = useNavigate();

  const [baggageAdditionalInfo, setBaggageAdditionalInfo] = useState({
    placeOfLoss: "",
    dateOfLoss: "",
    fullDetails: "",
    dateOfReportingToPolice: "",
    dateOfReportingToAirline: "",
    householdCountentsDetails: "",
    dateOfReportingToOperator: "",
  });

  const [isReportedToPolice, setIsReportedToPolice] = useState<boolean>(false);
  const [isReportedToAirline, setIsReportedToAirline] =
    useState<boolean>(false);
  const [isReportedToInsurance, setIsReportedToInsurance] =
    useState<boolean>(false);
  const [isReportedToOperator, setIsReportedToOperator] =
    useState<boolean>(false);

  useEffect(() => {
    setCurrentScreen("baggage-claim-additional");
    sessionStorage.setItem("claim-screens", "baggage-claim-additional");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setBaggageAdditionalInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveDetails = () => {
    if (!baggageAdditionalInfo.placeOfLoss) {
      document.getElementById("placeOfLoss")?.focus;
      toast.warning("Enter Place of loss!");
      return;
    }
    if (!baggageAdditionalInfo.dateOfLoss) {
      document.getElementById("dateOfLoss")?.focus;
      toast.warning("Enter date of loss!");
      return;
    }
    if (!baggageAdditionalInfo.fullDetails) {
      document.getElementById("fullDetails")?.focus;
      toast.warn("Enter full details!");
      return;
    }
    if (isReportedToPolice) {
      if (!baggageAdditionalInfo.dateOfReportingToPolice) {
        document.getElementById("dateOfReportingToPolice")?.focus;
        toast.warn("Enter the date that you reported to the police!");
        return;
      }
    }
    if (isReportedToAirline) {
      if (!baggageAdditionalInfo.dateOfReportingToAirline) {
        document.getElementById("dateOfReportingToAirline")?.focus;
        toast.warn("Enter the date that you reported to the Airline!");
        return;
      }
    }
    if (isReportedToInsurance) {
      if (!baggageAdditionalInfo.householdCountentsDetails) {
        document.getElementById("householdCountentsDetails")?.focus;
        toast.warn("Enter provide cover details!");
        return;
      }
    }
    if (isReportedToOperator) {
      if (!baggageAdditionalInfo.dateOfReportingToOperator) {
        document.getElementById("dateOfReportingToOperator")?.focus;
        toast.warn("Enter the date that you reported to the tour Operator!");
        return;
      }
    }
    setCurrentScreen("claim-documents");
    sessionStorage.setItem("claims-back", "baggage-claim-additional");
    sessionStorage.setItem("claim-screens", "claim-documents");
    sessionStorage.setItem(
      "claimInfo",
      JSON.stringify(baggageAdditionalInfo)
    );
    sessionStorage.setItem("claim_type", "Baggage Claim")
  };
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travel icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>
            Some additional information for your
          </span>{" "}
          <span className="span-title">Baggage Claim...</span>
        </div>
        <div className="cancellation-input">
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Place of loss/damage{" "}
                </label>
                <br />
                <div className="apartment-input" style={{ width: "100%" }}>
                  <input
                    id="placeOfLoss"
                    name="placeOfLoss"
                    type="text"
                    onChange={handleInputChange}
                    value={baggageAdditionalInfo.placeOfLoss}
                    placeholder="Name"
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Date of loss/damage{" "}
                </label>
                <br />
                <div className="apartment-input" style={{ width: "100%" }}>
                  <input
                    id="dateOfLoss"
                    name="dateOfLoss"
                    type="date"
                    onChange={handleInputChange}
                    value={baggageAdditionalInfo.dateOfLoss}
                    placeholder="Name"
                  ></input>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-input-wrapper" style={{ width: "100%" }}>
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="fullDetails">
                Full details of circumstances that led to the loss/damage
              </label>
              <br />
              <input
                id="fullDetails"
                name="fullDetails"
                type="text"
                onChange={handleInputChange}
                value={baggageAdditionalInfo.fullDetails}
                placeholder="Type here"
              ></input>
            </motion.div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Was the loss/damage reported to the police?{" "}
                </label>
                <br />
                <div className="countries d-flex mb-3">
                  <button
                    className={
                      isReportedToPolice
                        ? "countries-selection multiple-country baggage-button isOption-active"
                        : "countries-selection multiple-country baggage-button"
                    }
                    onClick={() => setIsReportedToPolice(true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !isReportedToPolice
                        ? "countries-selection one-country baggage-button isOption-active"
                        : "countries-selection one-country baggage-button"
                    }
                    onClick={() => setIsReportedToPolice(false)}
                  >
                    No
                  </button>
                </div>
                {isReportedToPolice && (
                  <div className="apartment-input" style={{ width: "100%" }}>
                    <label htmlFor="" className="text-left-label mb-2">
                      Date of reporting
                    </label>
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                    >
                      {" "}
                      <input
                        id="dateOfReportingToPolice"
                        name="dateOfReportingToPolice"
                        type="date"
                        onChange={handleInputChange}
                        value={baggageAdditionalInfo.dateOfReportingToPolice}
                        placeholder="Name"
                      ></input>{" "}
                    </motion.div>
                  </div>
                )}
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Was the loss/damage reported to the airline?{" "}
                </label>
                <br />
                <div className="countries d-flex mb-3">
                  <button
                    className={
                      isReportedToAirline
                        ? "countries-selection multiple-country baggage-button isOption-active"
                        : "countries-selection multiple-country baggage-button"
                    }
                    onClick={() => setIsReportedToAirline(true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !isReportedToAirline
                        ? "countries-selection one-country baggage-button isOption-active"
                        : "countries-selection one-country baggage-button"
                    }
                    onClick={() => setIsReportedToAirline(false)}
                  >
                    No
                  </button>
                </div>
                {isReportedToAirline && (
                  <div className="apartment-input" style={{ width: "100%" }}>
                    <label htmlFor="" className="text-left-label mb-2">
                      Date of reporting
                    </label>
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                    >
                      <input
                        id="dateOfReportingToAirline"
                        name="dateOfReportingToAirline"
                        type="date"
                        onChange={handleInputChange}
                        value={baggageAdditionalInfo.dateOfReportingToAirline}
                        placeholder="Name"
                      ></input>
                    </motion.div>
                  </div>
                )}
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Is your property also covered under a HouseholdContents
                  Insurance?{" "}
                </label>
                <br />
                <div className="countries d-flex mb-3">
                  <button
                    className={
                      isReportedToInsurance
                        ? "countries-selection multiple-country baggage-button isOption-active"
                        : "countries-selection multiple-country baggage-button"
                    }
                    onClick={() => setIsReportedToInsurance(true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !isReportedToInsurance
                        ? "countries-selection one-country baggage-button isOption-active"
                        : "countries-selection one-country baggage-button"
                    }
                    onClick={() => setIsReportedToInsurance(false)}
                  >
                    No
                  </button>
                </div>
                {isReportedToInsurance && (
                  <div className="apartment-input" style={{ width: "100%" }}>
                    <label htmlFor="" className="text-left-label mb-2">
                      Please provide cover details
                    </label>
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                    >
                      {" "}
                      <input
                        id="householdCountentsDetails"
                        name="householdCountentsDetails"
                        type="text"
                        onChange={handleInputChange}
                        value={baggageAdditionalInfo.householdCountentsDetails}
                        placeholder="Name"
                      ></input>{" "}
                    </motion.div>
                  </div>
                )}
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Was the loss/damage reported to the tour operator?{" "}
                </label>
                <br />
                <div className="countries d-flex mb-3">
                  <button
                    className={
                      isReportedToOperator
                        ? "countries-selection multiple-country baggage-button isOption-active"
                        : "countries-selection multiple-country baggage-button"
                    }
                    onClick={() => setIsReportedToOperator(true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !isReportedToOperator
                        ? "countries-selection one-country baggage-button isOption-active"
                        : "countries-selection one-country baggage-button"
                    }
                    onClick={() => setIsReportedToOperator(false)}
                  >
                    No
                  </button>
                </div>
                {isReportedToOperator && (
                  <div className="apartment-input" style={{ width: "100%" }}>
                    <label htmlFor="" className="text-left-label mb-2">
                      Date of reporting
                    </label>
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                    >
                      {" "}
                      <input
                        id="dateOfReportingToOperator"
                        name="dateOfReportingToOperator"
                        type="date"
                        onChange={handleInputChange}
                        value={baggageAdditionalInfo.dateOfReportingToOperator}
                        placeholder="Name"
                      ></input>
                    </motion.div>
                  </div>
                )}
              </motion.div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "baggage-claim");
                setCurrentScreen("baggage-claim");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default BaggageAdditionalInformation;
