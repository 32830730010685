import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makePostRequest } from "../../../../services/apiHandler";
import * as GrIcons from "react-icons/gr";
import { useWizard } from "react-use-wizard";
import Loader from "../../../../shared/loader/Loader";

const failIcon = require('../../../../../assets/images/rating-fail.png')
const successIcon = require('../../../../../assets/images/rating-suc.png')

const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

function CreditForm({ setCurrentScreen }: any) {
  const {goToStep } = useWizard();
  const [userInfo, setUserInfo] = useState({
    netincome: "",
    totalexpense: "",
    otherobligations: "",
    terms: "",
    employersName: "",
    employerPhysicalLocation: "",
  });

  const q: any = sessionStorage.getItem("quote") || {};

  const quote = JSON.parse(q);

  const [showSucDialog, setShowSucDialog] = useState(false);
  const [showFailDialog, setShowFailDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  const SKEY = sessionStorage.getItem("SKEY") || "";


  const u: any = sessionStorage.getItem("userInfo") || {};

  const user = JSON.parse(u);
  const d: any = sessionStorage.getItem("userDocs") || {};

  const doc = JSON.parse(d);

 
  const saveDetails = () => {
    if (!userInfo.employersName) {
      toast.warning("Enter Employers name!");
      document.getElementById("employersName")?.focus();
      return;
    }
    if (!userInfo.netincome) {
      toast.warning("Enter Net income!");
      document.getElementById("netincome")?.focus();
      return;
    }

    if (!userInfo.employerPhysicalLocation) {
      toast.warning("Enter Employers Physical location!");
      document.getElementById("employerPhysicalLocation")?.focus();
      return;
    }

    if (!userInfo.totalexpense) {
      toast.warning("Enter expense!");
      document.getElementById("totalexpense")?.focus();
      return;
    }

    if (!userInfo.otherobligations) {
      toast.warning("Enter other debts!");
      document.getElementById("otherobligations")?.focus();
      return;
    }

    if (!userInfo.terms) {
      toast.warning("Please Accept terms and conditions!");
      return;
    }

    sessionStorage.setItem("creditInfo", JSON.stringify(userInfo));

    getRating();

  };

  const getRating = async () => {
    setLoading(true);

    let data = {
      cover: "travel",
      firstname: user.userName,
      idnumber: doc.idNumber,
      lastname: user.userName,
      netincome: Number(userInfo.netincome),
      otherobligations: Number(userInfo.otherobligations),
      qid: quote._id,
      totalexpense: Number(userInfo.totalexpense),
    };

    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_CORE_API_URL}/creditrating`,
      {
        ...data,
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (result.Status === 200) {
      setLoading(false);
      let cre = result.Payload;
      sessionStorage.setItem("credit_resp", JSON.stringify(cre));
      if (cre.score) {
        showDialog("suc");
      } else {
        showDialog("fail");
      }
    }

    if (result.Status === 543 || result.Status === 401) {
      setLoading(false);
      toast.warning("Session expired please login again!");
      goToStep(3)
    }

    if (result.Status === 501) {
      setLoading(false);
      toast.error("Something went wrong! Try again.");
    }

    setLoading(false);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const showDialog = (status: any) => {
    if (status === "suc") {
      setShowSucDialog(true);
    }

    if (status === "fail") {
      setShowFailDialog(true);
    }

    let elem: any = document.getElementById("credit-rting-cont") || "";

    elem.style.filter = "blur(5px)";
    elem.style.background = "rgba(0, 0, 0, 0.05)";
  };

  const hideDialog = (status: any) => {
    if (status === "suc") {
      setShowSucDialog(false);
    }

    if (status === "fail") {
      setShowFailDialog(false);
    }

    let elem: any = document.getElementById("credit-rting-cont") || "";

    elem.style.filter = "none";
    elem.style.background = "none";
  };

  const handlePP = () => {
    hideDialog("suc");
    sessionStorage.setItem("payment-screen","payment-schedule")
    setCurrentScreen("payment-schedule")
  };

  const handleDirect = () => {
    hideDialog("fail");
  };
  const successDialog = (
    <div className="l-dialog-container">
      <img src={successIcon} alt="later doc" />
      <p className="dialog-p-t mt-3">Verification Successful</p>
      <p className="dialog-p mt-3">
        Please proceed to select a suitable payment plan for your cover
      </p>
      <div onClick={() => handlePP()} className="mt-4 dialog-proc-btn">
        PROCEED
      </div>
      <div onClick={() => hideDialog("suc")} className="mt-3 dialog-canc-btn">
        CANCEL
      </div>
    </div>
  );

  const failDialog = (
    <div className="l-dialog-container">
      <img src={failIcon} alt="later doc" />
      <p className="dialog-p-t mt-3">Verification Unsuccessful</p>
      <p className="dialog-p mt-3">
        Please proceed to make a one time payment for your cover
      </p>
      <div onClick={() => handleDirect()} className="mt-4 dialog-proc-btn">
        PROCEED
      </div>
      <div onClick={() => hideDialog("fail")} className="mt-3 dialog-canc-btn">
        CANCEL
      </div>
    </div>
  );

  return (
    <div>
      {showSucDialog && successDialog}
      {showFailDialog && failDialog}
      <div id="credit-rting-cont">
        <div className="container">
          <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit={{ opacity: 0 }}
          >
            <br />
            <div className="reg-title text-center">
              Let’s get you a{" "}
              <span className="span-title"> payment plan...</span>
            </div>
            <br />
            <div className="ky-inputs-container">
              <div className="ky-inputs-inner-container w-100">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="employersName"
                    >
                      Employers Name
                    </label>
                    <br />
                    <input
                      id="employersName"
                      name="employersName"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.employersName}
                      placeholder="Type Name"
                    ></input>
                  </motion.div>
                </div>
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="netincome">
                      Net Monthly Income (in Kshs.){" "}
                    </label>
                    <br />
                    <input
                      id="netincome"
                      name="netincome"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.netincome}
                      placeholder="Enter income"
                    ></input>
                  </motion.div>
                </div>
              </div>

              <div className="ky-inputs-inner-container w-100">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="employerPhysicalLocation"
                    >
                      Employer’s physical location
                    </label>
                    <br />
                    <input
                      id="employerPhysicalLocation"
                      name="employerPhysicalLocation"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.employerPhysicalLocation}
                      placeholder="Type Location"
                    ></input>
                  </motion.div>
                </div>
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="totalexpense"
                    >
                      Monthly Expenses (in Kshs.)
                    </label>
                    <br />
                    <input
                      id="totalexpense"
                      name="totalexpense"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.totalexpense}
                      placeholder="Enter Expences"
                    ></input>
                  </motion.div>
                </div>
              </div>
              <div className="ky-inputs-inner-container w-100">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="idnumber">
                      ID Number
                    </label>
                    <br />
                    <input
                      className="id-number-input"
                      disabled
                      id="idnumber"
                      name="idnumber"
                      type="text"
                      onChange={handleInputChange}
                      value={doc.idNumber}
                      placeholder="Enter ID number"
                    ></input>
                  </motion.div>
                </div>
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="otherobligations"
                    >
                      Other Montly debt payments (in Kshs.)
                    </label>
                    <br />
                    <input
                      id="otherobligations"
                      name="otherobligations"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.otherobligations}
                      placeholder="Enter other debt"
                    ></input>
                  </motion.div>
                </div>
              </div>
              <div className="mt-5 d-flex justify-content-start alig-items-start trustgro">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  onChange={handleInputChange}
                  value={userInfo.terms}
                />
                <div className="terms-link">
                  <span className="terrms-txt">
                    I have read, understood and agreed to{" "}
                    <a
                    target="_blank"
                      href="https://mtekcdnstoragev2.blob.core.windows.net/admin/TrustGro_IPF_Terms_and_Conditions_29112021.pdf
    "
                    >
                      TrustGro’s Terms and Conditions
                    </a>{" "}
                    for funding, and hereby authorise mTek to submit an
                    application for funding on my behalf to TrustGro, subject to
                    the Terms & Conditions
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div className="previous">
                  <span onClick={(e) => {
                    sessionStorage.setItem("payment-screen", "payment-opt")
                    sessionStorage.setItem("payment-plan", "false")
                    setCurrentScreen("payment-opt")
                    }}>
                    <span className="GrIcon me-2">
                      <GrIcons.GrFormPreviousLink />
                    </span>{" "}
                    <span className="previous">Previous</span>
                  </span>
                </div>
                {loading && <Loader />}
                {!loading && <div onClick={() => saveDetails()}>
                  <button className="next-btn">
                    {" "}
                    <span>
                      Proceed{" "}
                      <span className="GrIcon ms-3">
                        <GrIcons.GrFormNextLink />
                      </span>
                    </span>{" "}
                  </button>
                </div>}
              </div>
            </div>
            <br />
            <br />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default CreditForm;
