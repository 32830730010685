import React, { useRef, useState, useEffect } from "react";
import { makeGetRequest } from "../../services/apiHandler";
import "./fileupload.css";

let tickIcon = require("../../../assets/images/tick.png");
let errorIcon = require("../../../assets/images/error-Icon.png");

const InputFile = ({
  fileLocation,
  aFileName,
  allowedFileExtensions,
  refIcon,
  updateDocs,
  name,
  imageUrl,
}: {
  fileLocation: string;
  aFileName: string;
  allowedFileExtensions: any;
  refIcon: any;
  updateDocs: any;
  name: string;
  imageUrl?: string;
}) => {
  const [fileUploading, setFileUploading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const sasToken = sessionStorage.getItem("sasToken");

  const inputFileRef = useRef<HTMLInputElement>(null);

  const uploadFiles = (files: FileList | null) => {
    const file: any = files;
    const sFile = file[0];

    if (!sFile) {
      return;
    }

    const fileExtension = getFileExtension(sFile.name);
    upload(sFile, fileExtension);
  };

  const getFileExtension = (fileName: string): string => {
    let arr = fileName.split(".");
    return arr[arr.length - 1];
  };

  useEffect(() => {
    setImgUrl(imageUrl ?? String)
  }, [imageUrl]);

  const upload = async (sFile: any, fileExtension: any) => {
    setFileUploading(true);
    setError(false);
    setMessage("");
    setFileUploaded(false);

    if (!allowedFileExtensions.includes(fileExtension)) {
      setFileUploading(false);
      setError(true);
      setMessage(
        `Only ${allowedFileExtensions.join(" or ")} formats are supported`
      );
      return;
    }

    let fileName = `${aFileName}.${fileExtension}`;
    let url = `${process.env.REACT_APP_AZURE_BLOB_URL}/${sessionStorage.getItem(
      "uid"
    )}/${fileLocation}/${Date.now()}/${fileName}`;
    let fullUrl = `${url}${sasToken}`;

    const formData: FormData = new FormData();
    formData.append(fileName, sFile);

    var blob = new Blob([sFile], { type: sFile.type });

    try {
      const result = await fetch(fullUrl, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": sFile.type,
        },
        body: blob,
      });
      setFileUploading(false);

      if (result.status === 201) {
        if (fileExtension === "pdf") {
          setIsPdf(true);
        } else {
          setIsPdf(false);
        }

        setImgUrl(result.url);
        setFileUploaded(true);
        updateDocs(name, url);
      } else {
        setError(true);
        setMessage("Something went wrong! Please try again");
      }
    } catch (error) {
      if (error) {
        setFileUploading(false);
        setError(true);
        setMessage("Something went wrong! Please try again");
      }
    }
  };

  const showFileDialog = () =>
    inputFileRef.current && inputFileRef.current.click();

 
  return (
    <div className="input-file">
      <input
        style={{ display: "none" }}
        ref={inputFileRef}
        type="file"
        multiple={false}
        onChange={(e) => uploadFiles(e.target.files)}
      />
      <div onClick={() => showFileDialog()} className="id-uploads-container">
        <img src={refIcon} alt="Upload icon" />
      </div>
      <div className="upload-status-container">
        {fileUploading && (
          <div>
            <div className="loadingio-spinner-bars-ckokqeh99t">
              <div className="ldio-z1gxsuzd9od">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        )}

        {!isPdf && fileUploaded && imgUrl && (
          <div className="upload-img-container">
            <img className="tick-icon-i" src={tickIcon} alt="Uploaded Doc" />
            <img className="uploaded-img" src={imgUrl} alt="Uploaded Doc" />
          </div>
        )}

        {isPdf && fileUploaded && imgUrl && (
          <div className="uploaded-file-status">
            <span>Uploaded</span>
            <img className="tick-icon" src={tickIcon} alt="Uploaded Doc" />
          </div>
        )}

        {error && (
          <div className="error-msg">
            <img src={errorIcon} alt="error icon"></img>
            <span>{message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFile;
