import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useWizard } from "react-use-wizard";
import Steps from "../steps/Steps";
import * as GrIcons from "react-icons/gr";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";
import { variants } from "../../shared/variants";
import { britamInsurer } from "../../enums/appNums";
import { useMixpanel } from "react-mixpanel-browser";

function PrepareDocuments() {
  const mixpanel = useMixpanel()
  const { previousStep, nextStep, goToStep } = useWizard();
  const quote = JSON.parse(sessionStorage.getItem("quote")!);
  const insurer = quote?.insurer;
  const SKEY = sessionStorage.getItem("SKEY")!;
  const dispatch = useDispatch();
  const getToPreviousScreen = () => {
    if (SKEY) {
      if (dispatch(stepActions.onRemoveStepUpto(2))) {
        goToStep(2);
      }
    } else {
      if (dispatch(stepActions.onRemoveStep())) {
        previousStep();
      }
    }
  };

  useEffect(()=> {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Prepare-documents",
      });
    }
  }, [mixpanel])

  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="prepare" />
      <div className="container">
        <div className="title">
          <div className="prepare-title span-title">
            Now, let’s help you buy your insurance!
          </div>
          <div className="prepare-title-2">
            Please ready the following documents for upload...
          </div>
        </div>
        <div className="prepare-details">
          <ul>
            <li>National ID/ Passport</li>
            {insurer == britamInsurer && <li>KRA PIN Certificate</li>}
            {quote?.policy?.reason == "STUDIES" && <li>Proof Of Schooling</li>}
          </ul>
        </div>
        <div className="prepare-details-btn text-center">
          <button
            className="pointer-btn"
            onClick={() => {
              if (dispatch(stepActions.onAddStep())) {
                nextStep();
              }
            }}
          >
            Let’s Proceed
          </button>
        </div>
        <div className="previous">
          <span onClick={getToPreviousScreen}>
            <span className="GrIcon me-2">
              <GrIcons.GrFormPreviousLink />
            </span>{" "}
            <span className="previous">Previous</span>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default PrepareDocuments;
