import "./App.css";

import { Route, Routes } from "react-router-dom";
import Steps from "./app/components/Steps";
import Policies from "./app/policies/Policies";
import Claims from "./app/claims/Claims";
import Auth from "./app/auth/app-auth/Auth";
import RenewalPayment from "./app/renewals/RenewalPayment";
import PaymentMethod from "./app/payments/payment-methods/PaymentMethod";
import Mpesa from "./app/payments/payment-methods/mpesa/Mpesa";
import Visa from "./app/payments/payment-methods/visa/Visa";
import Confirmation from "./app/payments/confirmation/Confirmation";
import { useEffect, useState } from "react";
import ListClaims from "./app/claims/list-claims/ListClaims";
import { MixpanelProvider } from "react-mixpanel-browser";

function App() {
  const rememberMe = JSON.parse(localStorage.getItem("rememberMe")!) === true;
  const loggedIn = JSON.parse(localStorage.getItem("loggedIn")!) === true;
  const userLoggedIn = JSON.parse(sessionStorage.getItem("loggedIn")!) === true;
  const [isLoggedIn, setIsLoggedIn] = useState<any>(loggedIn);
  const uid = localStorage.getItem("uid")!;
  const accessToken = localStorage.getItem("SKEY")!;
  const displayEmail = localStorage.getItem("email")!;
  const userEmail = sessionStorage.getItem("email")!;
  const displayName = localStorage.getItem("userName")!;
  const email = displayEmail ? displayEmail : userEmail;
  const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

  // [OPTIONAL] Custom options to pass to `mixpanel.init()`.
  const MIXPANEL_CONFIG = {
    track_PageView: true, // Set to `false` by default
  };

  useEffect(() => {
    if (rememberMe && loggedIn) {
      sessionStorage.setItem("uid", uid);
      sessionStorage.setItem("SKEY", accessToken);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("userName", displayName);
      setIsLoggedIn(loggedIn);
    } else if (userLoggedIn) {
      setIsLoggedIn(userLoggedIn);
    } else {
      setIsLoggedIn(false);
    }
  }, [rememberMe, loggedIn, userLoggedIn]);
  return (
    <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
      <Routes>
        <Route path="/" element={<Steps />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/claims" element={<Claims />} />
        <Route path="/list-claims" element={<ListClaims />} />
        <Route path="/payments/renewal" element={<RenewalPayment />} />
        {/* <Route path="/payments/make" element={<PaymentMethod />} /> */}
        <Route path="/payments/make/mpesa" element={<Mpesa />} />
        <Route path="/payments/make/visa" element={<Visa />} />
        <Route path="/payments/make/confirm" element={<Confirmation />} />
        <Route path="*" element={<Steps />} />
      </Routes>
    </MixpanelProvider>
  );
}

export default App;
