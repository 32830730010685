import { motion } from "framer-motion";
import React, { useEffect } from "react";
import * as GrIcons from "react-icons/gr";
import { useMixpanel } from "react-mixpanel-browser";
import { variants } from "../../../shared/variants";

function PersonalInformation({ setCurrentScreen }: any) {
  const d: any = sessionStorage.getItem("userDocs") || {};

  const doc = JSON.parse(d);

  const u: any = sessionStorage.getItem("userInfo") || {};

  const user = JSON.parse(u);


  const mixpanel = useMixpanel()

  useEffect(()=> {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Personal-summary",
      });
    }
  }, [mixpanel])

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div className="reg-title text-center">
          Your <span style={{ color: "#30C2DB" }}>personal information...</span>
        </div>
        <br />
        <div className="confirmation-container">
          <div className="confirmation-wapper">
            <div className="conf-sect-group mb-2 mt-3">
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Full Name</span>
                <span className="conf-keys">{user.userName}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Email Address</span>
                <span className="conf-keys">
                  {sessionStorage.getItem("email")}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Phone Number</span>
                <span className="conf-keys">{user.phoneNumber}</span>
              </div>
            </div>

            <div className="mt-4 mb-4">
              <div className="cr-line"></div>
            </div>

            <div className="conf-sect-group mb-3 mt-3">
              {doc.idNumber && (
                <div className="d-flex justify-content-between">
                  <span className="conf-keys">ID Number</span>
                  <span className="conf-keys">{doc.idNumber}</span>
                </div>
              )}
              {doc.passportNumber && (
                <div className="d-flex justify-content-between">
                  <span className="conf-keys">Passport Number</span>
                  <span className="conf-keys">{doc.passportNumber}</span>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem(
                    "summary-confirmation-screen",
                    "cover-details"
                  );
                  setCurrentScreen("cover-details");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div
              onClick={() => {
                sessionStorage.setItem(
                  "summary-confirmation-screen",
                  "beneficiary-information"
                );
                setCurrentScreen("beneficiary-information");
              }}
            >
              <button className="next-btn">
                {" "}
                <span>
                  Next{" "}
                  <span className="GrIcon ms-3">
                    <GrIcons.GrFormNextLink />
                  </span>
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default PersonalInformation;
