import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import "./claimOption.css";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

import mtekLogo  from "../../../assets/images/mtek-logo.svg"


const delay = require("../../../assets/images/delay.png");
const liability = require("../../../assets/images/liability.png");
const medical = require("../../../assets/images/medical.png");
const baggage = require("../../../assets/images/baggage.png");
const curtailment = require("../../../assets/images/curtailment.png");
const cancellation = require("../../../assets/images/cancellation.png");
const departure = require("../../../assets/images/missed-departure.png");

function ClaimOptions({ setCurrentScreen }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentScreen("claim-options");
    sessionStorage.setItem("claim-screens", "claim-options");
  });
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travel icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>Please select your</span>
          <span className="span-title"> claim option</span>
          <span> below...</span>
        </div>
        <div className="claim-options">
          <div
            className="claim-option baggage"
            onClick={() => {
              setCurrentScreen("baggage-claim");
              sessionStorage.setItem("claim-screens", "baggage-claim");
            }}
          >
            <img src={baggage} alt="bagged icon" />
          </div>
          <div
            className="claim-option cancellation"
            onClick={() => {
              setCurrentScreen("claim-cancellation");
              sessionStorage.setItem("claim-screens", "claim-cancellation");
            }}
          >
            <img src={cancellation} alt="cancellation icon" />
          </div>
          <div
            className="claim-option medical"
            onClick={() => {
              setCurrentScreen("claim-medical");
              sessionStorage.setItem("claim-screens", "claim-medical");
            }}
          >
            <img src={medical} alt="medical icon" />
          </div>
          <div
            className="claim-option missed-departure"
            onClick={() => {
              setCurrentScreen("claim-departure");
              sessionStorage.setItem("claim-screens", "claim-departure");
            }}
          >
            <img src={departure} alt="missed-departure icon" />
          </div>
          <div
            className="claim-option curtailment"
            onClick={() => {
              setCurrentScreen("claim-curtailment");
              sessionStorage.setItem("claim-screens", "claim-curtailment");
            }}
          >
            <img src={curtailment} alt="curtailment icon" />
          </div>
          <div
            className="claim-option personal-liability"
            onClick={() => {
              setCurrentScreen("claim-liability");
              sessionStorage.setItem("claim-screens", "claim-liability");
            }}
          >
            <img src={liability} alt="personal-liability icon" />
          </div>
          <div
            className="claim-option travel-delay"
            onClick={() => {
              setCurrentScreen("claim-delay");
              sessionStorage.setItem("claim-screens", "claim-delay");
            }}
          >
            <img src={delay} alt="delay icon" />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center claim-option-btn mb-5">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "make-claim");
                setCurrentScreen("make-claim");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default ClaimOptions;
