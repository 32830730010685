import { configureStore } from "@reduxjs/toolkit";
import  StepSlice  from "./Steps"
import IndividualQuotesPayloadSlice from "./IndividualPayload";
import GroupQuotesPayloadSlice from "./GroupPayload";
import GroupMembersSlice from "./GroupMembers";
import TravelPayloadCurrentScreenSplice from "./CurrentTravelScreen";
import ProfileSlice from "./UserProfileData";

const store = configureStore({
    reducer: {
        step :StepSlice.reducer,
        individualData: IndividualQuotesPayloadSlice.reducer,
        groupData: GroupQuotesPayloadSlice.reducer,
        groupMembers: GroupMembersSlice.reducer,
        travelPayloadCurrentScreen: TravelPayloadCurrentScreenSplice.reducer,
        profile: ProfileSlice.reducer
    }
})

export default store