export const setSessionData = (key: string, data: any) => {
  let d = getSessionData(key);
  let od = { ...d, ...data };
  sessionStorage.setItem(key, JSON.stringify(od));
};

export const getSessionData = (key: string) => {
  let d = sessionStorage.getItem(key);
  if (d) {
    return JSON.parse(d);
  }
  return {};
};
