import { createSlice } from "@reduxjs/toolkit";

let individual = JSON.parse(sessionStorage.getItem("individualData")!)
    ? JSON.parse(sessionStorage.getItem("individualData")!)
    : {}

const IndividualQuotesPayloadSlice = createSlice({
    initialState: individual,
    name: "individualPayload",
    reducers: {
        setIndividualPayload(state, action) {
            state.individual = action.payload;
            sessionStorage.setItem("individualData", JSON.stringify({ individual: state?.individual }));
            return state
          },
    }
})

export const getIndividualPayloadActions = IndividualQuotesPayloadSlice.actions;

export default IndividualQuotesPayloadSlice;