import { createSlice } from "@reduxjs/toolkit";

let groupMembers = JSON.parse(sessionStorage.getItem("group_members")!)
  ? JSON.parse(sessionStorage.getItem("group_members")!)
  : [];

const GroupMembersSlice = createSlice({
  initialState: groupMembers,
  name: "groupMembers",
  reducers: {
    setGroupMembersPayload(state, action) {
      state.groupMembers = action?.payload;
      sessionStorage.setItem("group_members", JSON.stringify(state?.groupMembers));
      return state
    },
  },
});

export const groupMembersActions = GroupMembersSlice.actions;

export default GroupMembersSlice;
