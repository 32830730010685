import { motion } from "framer-motion";
import React from "react";
import { variants } from "../../shared/variants";
import mtekLogo  from "../../../assets/images/no-quotes.svg"

function NoQuotesFoundPage() {

  let reasons = [
    "We do not have an active insurer covering your country of choice.",
    "The number of days you have selected could be more that what we currently support.",
    "Your reason for travelling might not have a country supporting it",
  ];

  return (
    <motion.div
      className="no-quotes-container"
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      <p className="reg-title" style={{ marginTop: "-2rem" }}>
        <span className="span-title"> Sorry, no quotes found!</span>
      </p>
      <div className="image mt-2 mb-2">
        <img src={mtekLogo} alt="" />
      </div>
      <div className="no-quotes-reason mt-3">
        <ul>
          {reasons?.map((reason, index) => {
            return <li key={index}>{reason}</li>;
          })}
        </ul>
      </div>
      <div className="no-quotes-description">
        Please click on the button below to try again.
      </div>
    </motion.div>
  );
}

export default NoQuotesFoundPage;
