import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Steps from "../steps/Steps";
import IndividualDetails from "./IndividualDetails";
import GroupDetails from "./GroupDetails";
import { variants } from "../../shared/variants";
import { useSelector } from "react-redux";
import WhatsppGroupMembers from "./WhatsppGroupMembers";
import FamilyDetails from "./FamilyDetails";
import WhatsppFamilyMembers from "./WhatsppFamilyMembers";
import { useMixpanel } from "react-mixpanel-browser";

function TravelDetails() {
  const mixpanel = useMixpanel()
  const traveScreenSelector = useSelector(
    (state: any) => state.travelPayloadCurrentScreen
  );
  const [currentScreen, setCurrentScreen] = useState(traveScreenSelector);
  const location = window.location.search;
  const groupid = new URLSearchParams(location).get("groupid");
  const familyid = new URLSearchParams(location).get("familyid");

  useEffect(() => {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|Travel-details",
      });
    }
    setCurrentScreen(traveScreenSelector);
    
  }, [currentScreen, mixpanel]);
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="travel-details" />
      <div className="travel-container">
        {groupid ? (
          <p className="travel-details-title">
            Proceed to provide the{" "}
            <span className="span-title">travel details</span> of the other
            members
          </p>
        ) : familyid ? (
          <p className="travel-details-title">
            Proceed to provide the{" "}
            <span className="span-title">travel details</span> of the family
            members
          </p>
        ) : (
          <p className="travel-details-title">
            Proceed to provide your{" "}
            <span className="span-title">travel details</span>{" "}
          </p>
        )}
        {groupid ? (
          <WhatsppGroupMembers />
        ) : familyid ? (
          <WhatsppFamilyMembers />
        ) : (
          <>
            {currentScreen === "individual" && (
              <IndividualDetails
                setCurrentScreen={setCurrentScreen}
                individualStatus={true}
                groupStatus={false}
                familyStatus={false}
              />
            )}
            {currentScreen === "group" && (
              <GroupDetails
                setCurrentScreen={setCurrentScreen}
                individualStatus={false}
                groupStatus={true}
                familyStatus={false}
              />
            )}
            {currentScreen === "family" && (
              <FamilyDetails
                setCurrentScreen={setCurrentScreen}
                individualStatus={false}
                groupStatus={false}
                familyStatus={true}
              />
            )}
          </>
        )}
      </div>
    </motion.div>
  );
}

export default TravelDetails;
