import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { makePostRequest, saveSharedQuote } from "../../../services/apiHandler";
import Steps from "../../steps/Steps";
import Quote from "../Quote/Quote";
import * as GrIcons from "react-icons/gr";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { stepActions } from "../../../store/Steps";
import Loader from "../../../shared/loader/Loader";
import Auth from "../../../auth/Auth";
import { Dialog, DialogContent } from "@mui/material";
import { variants } from "../../../shared/variants";
import NoQuotesFoundPage from "../NoQuotesFoundPage";
import { useMixpanel } from "react-mixpanel-browser";

let cancel = require("../../../../assets/images/Cancel.png");
let undraw_fans = require("../../../../assets/images/undraw_fans.png");

function Quotes() {
  const mixpanel = useMixpanel();
  const { previousStep, goToStep } = useWizard();
  const dispatch = useDispatch();
  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const [quotes, setQuotes] = useState<any[]>([]);
  const [underwriters, setUnderwriters] = useState<any>();
  const [displayNewButtons, setDisplayNewButtons] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showSucDialog, setShowSucDialog] = useState(false);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [generatedQuotesID, setGeneratedQuotesID] = useState<string>();
  const [quoteIDs, setQuoteIDs] = useState<string[]>([]);

  const [sendQuoteData, setSendQuotesData] = useState<ISendQuoteData>({
    name: "",
    email: "",
  });

  const SKEY = sessionStorage.getItem("SKEY");
  const location = window.location.search;

  const generatedQuoteID = new URLSearchParams(location).get(
    "generatedQuoteId"
  );

  const getQuotesLocation = window.location.search;

  const getquotesFromWhatsspp = new URLSearchParams(getQuotesLocation).get(
    "getquotes"
  );

  const selectedQuoteData: any = sessionStorage.getItem("sharedQuotes");
  const newSelectedQuoteData: any[] = JSON.parse(selectedQuoteData);

  const travel_option = sessionStorage.getItem("travel_option")!;
  const info = sessionStorage.getItem("travelInfo")!;
  const travel_type = sessionStorage.getItem("travel_type");
  const g = sessionStorage.getItem("group_members")!;
  const group_members = JSON.parse(g);
  const userInfo = JSON.parse(info);
  const isTravellingToMultipleCountries =
    JSON.parse(sessionStorage.getItem("isTravellingToMultipleCountries")!) ===
    true;

  const getQuotes = async () => {
    setLoadingQuotes(true);
    let reqObject: any = {};

    reqObject = {
      headers: {
        channel_platform: getquotesFromWhatsspp ? "whatspp" : "web",
        channel_actor: "client",
        channel_org: `${process.env.REACT_APP_MODULE_ID}`,
        channel_product: "travel",
      },
    };
    if (generatedQuoteID) {
      delete reqObject?.headers;
      reqObject = {
        quote_request_id: generatedQuoteID,
      };
      getSharedQuotes(reqObject);
    } else if (getquotesFromWhatsspp) {
      reqObject = {
        quote_request_id: getquotesFromWhatsspp,
      };
      allQuotes(reqObject);
    } else {
      const location = window.location.search;
      const groupid = new URLSearchParams(location).get("groupid");
      const familyid = new URLSearchParams(location).get("familyid");
      if (groupid || familyid) {
        let data = JSON.parse(sessionStorage.getItem("whatsppPaload")!);
        allQuotes(data);
      } else {
        allQuotes(getQuotesBody());
      }
    }
  };

  const allQuotes = async (payload) => {
    setLoadingQuotes(true);

    const socket = new WebSocket(`${process.env.REACT_APP_TRAVEL_WEBSOCKETS}`);

    socket.onopen = () => {
      socket.send(JSON.stringify({ ...payload }));
    };

    socket.onmessage = (event) => {
      let responseData: IApiResponse = JSON.parse(event.data);
      if (responseData.Status == 200) {
        setQuotes((prevData) => [...prevData, responseData.Payload]);
      }
      setLoadingQuotes(false);
    };

    return () => {
      socket.close();
    };
  };

  const getSharedQuotes = async (payload: any) => {
    setLoadingQuotes(true);
    const res: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_CORE_API_URL}/listsharedquotes`,
      {
        ...payload,
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (res.Status === 200) {
      let policiesData = res.Payload;
      if (Array.isArray(policiesData)) {
        setQuotes(policiesData);
      } else {
        setQuotes([]);
      }
      setLoadingQuotes(false);
    } else {
      if (res.Status === 541 || res.Status === 401 || res.Status === 543) {
        sessionStorage.setItem("openLogin", JSON.stringify(true));
        setShowLoginDialog(true);
      } else {
        setLoadingQuotes(false);
      }
    }
  };

  const getQuotesBody = (): IUserInfo => {
    let userStartDate = new Date(userInfo?.startDate);
    let birthDate = new Date(userInfo?.yearOfBirth);
    let userEndDate = new Date(userInfo?.endDate);
    const currentDate = new Date();
    const nextYear = currentDate.getFullYear() + 1;
    currentDate.setFullYear(nextYear);
    let mxDistinctId = new URLSearchParams(location).get("mxDistinctId");
    let mxDistinctIdObj = {};
    if (mxDistinctId) {
      mxDistinctIdObj = {
        mxDistinctId: mxDistinctId,
      };
    } else {
      mxDistinctIdObj = {
        mxDistinctId: sessionStorage.getItem("mxDistinctId")!,
      };
    }
    let body: any = {
      ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
      policy: {
        departure: {
          country: userInfo?.departureCountry,
          town: "unknown",
        },
        destinations: userInfo?.destinationCountry,
        endDay: userEndDate?.getDate(),
        endMonth: userEndDate?.getMonth() + 1,
        endYear: userEndDate?.getFullYear(),
        reason: userInfo?.reason,
        startDay: userStartDate?.getDate(),
        startMonth: userStartDate?.getMonth() + 1,
        startYear: userStartDate?.getFullYear(),
      },
      principle: {
        dayOfBirth: birthDate?.getDate(),
        monthOfBirth: birthDate?.getMonth() + 1,
        relationship: "PRINCIPLE",
        yearOfBirth: birthDate.getFullYear(),
      },
      headers: {
        channel_platform: getquotesFromWhatsspp ? "whatspp" : "web",
        channel_actor: "client",
        channel_org: `${process.env.REACT_APP_MODULE_ID}`,
        channel_product: "travel",
        ...mxDistinctIdObj,
      },
    };
    if (travel_type == "Group") {
      delete body.principle;
      body["group"] = [...group_members];
    }
    if (travel_type == "Family") {
      let principle = JSON.parse(sessionStorage.getItem("principle")!);
      body["dependents"] = [...group_members];
      body["principle"] = principle;
    }
    if (travel_option === "town") {
      if (!body.policy) {
        body.policy = {};
      }
      body.policy.departure = {
        country: "Kenya",
        town: userInfo.departureCountry,
      };
      body.policy.destinations = [
        {
          country: "Kenya",
          town: userInfo.destinationCountry,
        },
      ];
      return body;
    }
    return body;
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setSendQuotesData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchUnderwritters = async () => {
    const underwritersResponse: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_CORE_API_URL}/listInsuranceUnderwriters`,
      {}
    );
    if (underwritersResponse.Status == 200) {
      setUnderwriters(underwritersResponse.Payload);
    }
  };

  const handleSelectAll = () => {
    let originalQuotes = [...quotes];
    let userSharedQuotes: any[] = [];
    let allQuotes = originalQuotes.map((uQuote: any) => {
      uQuote.isChecked = true;
      let oneQuote: any = {};

      if (!uQuote._id) {
        uQuote._id = Math.floor(Math.random() * 1000 * 100);
      }
      oneQuote = {
        ...uQuote,
      };
      userSharedQuotes.push({
        ...oneQuote,
        _id: uQuote?._id,
      });
      return uQuote;
    });
    setSelectAll(true);
    sessionStorage.setItem("sharedQuotes", JSON.stringify(userSharedQuotes));
  };

  const handleUnSelectAll = () => {
    let updatedQuotes = quotes.map((quote) => {
      delete quote?.isChecked;
      delete quote?._id;
      return quote;
    });
    sessionStorage.removeItem("sharedQuotes");
    setSelectAll(false);
  };

  const loginDialog = (
    <div className="auth-dialog-container">
      <Auth />
    </div>
  );

  const showDialog1 = () => {
    setShowLoginDialog(true);
    sessionStorage.setItem("openLogin", JSON.stringify(true));
    // goToStep(3)
  };

  const showDialog2 = () => {
    const dialogData: any = sessionStorage.getItem("sharedQuotes");
    if (dialogData) {
      setShowEmailDialog(true);
      sessionStorage.setItem("openEmail", JSON.stringify(true));
      sessionStorage.setItem("openEmail", JSON.stringify(true));
    } else {
      alert("Select quote to share");
    }
  };
  const setIsDisplay = () => {
    sessionStorage.setItem("selectAll", JSON.stringify(false));
    setShowCheckbox(true);
    sessionStorage.setItem("showCheckbox", JSON.stringify(true));
    setDisplayNewButtons(true);
  };

  const checkIfGeneratedQuoteIDIsFound = (): boolean => {
    if (generatedQuoteID) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (mixpanel.config.token) {
      mixpanel.track("PageView", {
        pageName: "Travel|List-quotes",
      });
    }

    fetchUnderwritters();
    getQuotes();
    function checkUserData() {
      const aLogin: any = sessionStorage.getItem("openLogin");
      const openLogin = JSON.parse(aLogin);
      const aEmail: any = sessionStorage.getItem("openEmail");
      const openEmail = JSON.parse(aEmail);

      setShowLoginDialog(openLogin);
      setShowEmailDialog(openEmail);
    }

    window.addEventListener("storage", checkUserData);
    window.dispatchEvent(new Event("storage"));
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, [sessionStorage.getItem("openEmail"), mixpanel]);

  const validateEmail = (email: string) => {
    //eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const getQuotesID = async () => {
    setEmailLoading(true);

    try {
      // Step 1: Make the initial API call and get the _id
      const initialApiResponse: IApiResponse = await makePostRequest(
        `${process.env.REACT_APP_CORE_API_URL}/savequestions`,
        {
          email: userInfo.email,
          name: userInfo.name,
          phoneNumber: userInfo.phoneNumber,
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        },
        {
          SKEY: SKEY,
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
          apiKey: `${process.env.REACT_APP_MODULE_ID}`,
        }
      );

      // Extract the _id from the initial API response
      const questions_id = initialApiResponse?.Payload?._id;

      // Step 2: Save quotes and get their IDs
      const promises = newSelectedQuoteData?.map(
        (quote: any, index: number) => {
          delete quote?._id;
          delete quote?.isChecked;
          return saveSharedQuote(quote, questions_id)
            .then((res) => res)
            .catch((error) => {
              toast.error(`Error saving quote: ${error}\n `);
              return null;
            });
        }
      );

      // Wait for all quotes to be saved and get their IDs
      const SelectedQuoteIDs = await Promise.all(promises);
      setQuoteIDs(SelectedQuoteIDs);

      // Step 3: Send emails with the quote IDs
      await sendEmails(SelectedQuoteIDs);

      // Finalize and reset loading state
      setEmailLoading(false);
    } catch (error) {
      toast.error(`${error} error`);
      setEmailLoading(false);
    }
  };

  const sendEmails = async (quoteIDs: string[]) => {
    try {
      const apiResponse: IApiResponse = await makePostRequest(
        `${process.env.REACT_APP_CORE_API_URL}/sendquoteproposal`,
        {
          email: sendQuoteData.email,
          userName: sendQuoteData.name,
          policy: "travel",
          quotes: quoteIDs,
        },
        {
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
          apiKey: `${process.env.REACT_APP_MODULE_ID}`,
          SKEY: SKEY,
        }
      );

      if (apiResponse.Status === 200) {
        let sharedQuoteData = apiResponse.Payload;
        setShowSucDialog(true);
        setShowEmailDialog(false);
        setGeneratedQuotesID(sharedQuoteData?.generatedQuoteId);

        setSendQuotesData({
          email: "",
          name: "",
        });
        setQuoteIDs([]);
        setQuotes([]);
        sessionStorage.removeItem("sharedQuotes");
        setSelectAll(false);
      } else {
        toast.error(apiResponse.Payload);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const hideDialogs = () => {
    hideDialog();
    hideSuccessDialog();
    setQuotes([]);
    sessionStorage.removeItem("sharedQuotes");
    setSelectAll(false);
  };

  const hideDialog = () => {
    setShowEmailDialog(false);
    sessionStorage.setItem("openEmail", JSON.stringify(false));
  };
  const hideSuccessDialog = () => {
    setShowSucDialog(false);
    setShowEmailDialog(false);
    sessionStorage.setItem("openEmail", JSON.stringify(false));
    sessionStorage.setItem("showCheckbox", JSON.stringify(false));
    // goToStep(3);
  };

  const handlePreviouStep = () => {
    if (generatedQuoteID || getquotesFromWhatsspp) {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);

      // Remove the "generatedQuoteId" parameter
      searchParams.delete("generatedQuoteId");
      searchParams.delete("getquotes");
      // Update the URL without the parameter
      url.search = searchParams.toString();
      const newUrl = url.toString();

      // Redirect to the new URL without the parameter
      window.location.href = newUrl;
      dispatch(stepActions.onRemoveStep());
    } else {
      dispatch(stepActions.onRemoveStep());
      previousStep();
    }
  };

  const goToHome = () => {
    dispatch(stepActions.onRestartCurrentStepToDefault());
    goToStep(0);
  };

  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="quotes" />
      {!checkIfGeneratedQuoteIDIsFound() && (
        <div>
          <Dialog
            open={showEmailDialog ? showEmailDialog : false}
            closeAfterTransition={showEmailDialog ? showEmailDialog : false}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div className="l-dialog-container">
                <img
                  className="cancel-share-quotes-icon"
                  src={cancel}
                  alt="cancel"
                  onClick={() => hideDialog()}
                />
                <br />
                <p
                  className="dialog-p text-center"
                  style={{ fontWeight: "bold" }}
                >
                  Please enter name & email address to share quotes
                </p>
                <br />
                <input
                  className="share-quote-input"
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  value={sendQuoteData.name}
                  placeholder="Username"
                ></input>
                <input
                  className="share-quote-input"
                  id="email"
                  name="email"
                  type="text"
                  value={sendQuoteData.email}
                  onChange={handleInputChange}
                  placeholder="Johndoe@gmail.com"
                ></input>
                {!validateEmail(sendQuoteData.email) && sendQuoteData.email && (
                  <div className="error-msg">
                    <span>Please enter a valid email</span>
                  </div>
                )}
                {!emailLoading && (
                  <div
                    onClick={() => getQuotesID()}
                    className="mt-4 bg-green-button"
                    style={{
                      borderRadius: 0,
                      width: 150,
                      padding: 8,
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                  >
                    Send Quotes
                  </div>
                )}
                {emailLoading && <Loader />}
                <br /> <br />
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={showSucDialog ? showSucDialog : false}
            closeAfterTransition={showSucDialog ? showSucDialog : false}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div className="l-dialog-container">
                <img
                  src={cancel}
                  alt="cancel"
                  className="cancel-share-quotes-icon"
                  onClick={() => hideDialogs()}
                />
                <img
                  src={undraw_fans}
                  style={{ marginBottom: 40 }}
                  alt="later doc"
                />
                <p className="dialogs mt-5">Hurray!</p>
                <span className="dialogs" style={{ marginBottom: 18 }}>
                  Quotes have been successfully sent.
                </span>
                <p className="dialog-p text-center dialogs2">
                  To share these quotes, please copy the link below and send it
                  to your networks as well.
                </p>
                <label className="textDialog">
                  <span style={{ color: "grey", textAlign: "center" }}>
                    Your link <br />
                  </span>
                  {process.env.REACT_APP_SHARELINK_URL}/?generatedQuoteId=
                  {generatedQuotesID}
                </label>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
      <div className="container">
        {quotes.length > 0 && (
          <div className="mt-4">
            <div className="reg-title text-center">
              <span className="span-title">
                Travel Insurance {travel_type} Quotes
              </span>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center quotes-cont mt-4">
          {loadingQuotes && (
            <div>
              <Loader />
            </div>
          )}
          {showLoginDialog ? loginDialog : " "}
          {!loadingQuotes && quotes.length > 0 && (
            <div className="text-center container-share">
              {!displayNewButtons &&
                !showCheckbox &&
                !checkIfGeneratedQuoteIDIsFound() && (
                  <button
                    style={{ marginBottom: 35 }}
                    className="share-button"
                    onClick={() => setIsDisplay()}
                  >
                    Share these quotes?
                  </button>
                )}
              {displayNewButtons && showCheckbox && (
                <div className="bt-new">
                  {!selectAll && (
                    <p
                      className="handle-select"
                      onClick={() => handleSelectAll()}
                    >
                      Select All
                    </p>
                  )}
                  {selectAll && (
                    <p
                      className="handle-select"
                      onClick={() => handleUnSelectAll()}
                    >
                      Unselect All
                    </p>
                  )}
                  {!SKEY && (
                    <button
                      style={{ padding: 5 }}
                      className="share-button"
                      onClick={() => showDialog1()}
                    >
                      Share
                    </button>
                  )}
                  {SKEY && (
                    <button
                      style={{ padding: 5 }}
                      className="share-button"
                      onClick={() => showDialog2()}
                    >
                      Share
                    </button>
                  )}
                </div>
              )}

              <br />
            </div>
          )}
          {quotes.length <= 0 && !loadingQuotes && <NoQuotesFoundPage />}
          {quotes.length > 0 &&
            quotes.map((item: any, index: number) => {
              let result = underwriters?.find(
                (underwriter: any) => underwriter?._id === item?.insurer
              );
              var url =
                "https://www.mtek-services.com/wp-content/uploads/2021/01/Group-1100.svg";
              try {
                if (result) {
                  url = result.logoUrl;
                }
              } catch (e) {
                if (e) {
                  url =
                    "https://www.mtek-services.com/wp-content/uploads/2021/01/Group-1100.svg";
                }
              }
              return (
                <Quote
                  key={index}
                  quote={item}
                  url={url}
                  checkIfGeneratedQuoteIDIsFound={
                    checkIfGeneratedQuoteIDIsFound
                  }
                  showCheckbox={showCheckbox}
                />
              );
            })}
        </div>

        <div className="mt-2 pointer-button d-flex justify-content-between align-items-center">
          <div className="previous">
            <span onClick={() => handlePreviouStep()}>
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          {quotes.length <= 0 && (
            <div onClick={() => goToHome()}>
              <button className="next-btn">Get Another Quote</button>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
export default Quotes;
