import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import Select from "react-select";
import { toast } from "react-toastify";
import * as GrIcons from "react-icons/gr";
import * as IoIcons from "react-icons/io";
import { makeGetRequest } from "../../services/apiHandler";
import { stepActions } from "../../store/Steps";
import { useDispatch, useSelector } from "react-redux";
import { variants } from "../../shared/variants";
import { getGroupPayloadActions } from "../../store/GroupPayload";
import { groupMembersActions } from "../../store/GroupMembers";
import { travelCurrentScreenActions } from "../../store/CurrentTravelScreen";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const GiCancel = require("../../../assets/images/GiCancel.png");
const avatar = require("../../../assets/images/user2.png");

function FamilyDetails({
  setCurrentScreen,
  individualStatus,
  groupStatus,
  familyStatus,
}: any) {
  const travel_option = sessionStorage.getItem("travel_option")!;
  const groupPayload = useSelector((state: any) => state.groupData.group);
  const groupMembersSelector = useSelector((state: any) => state.groupMembers);

  const dispatch = useDispatch();
  const { previousStep, nextStep } = useWizard();
  const [travelInfo, setTravelInfo] = useState({
    startDate: groupPayload?.startDate ? groupPayload?.startDate : "",
    endDate: groupPayload?.endDate ? groupPayload?.endDate : "",
    reason: groupPayload?.reason ? groupPayload?.reason : "",
    departureCountry:
      travel_option == "inbound" || travel_option == "outbound" ? "Kenya" : "",
    destinationCountry:
      travel_option == "inbound" || travel_option == "outbound"
        ? groupPayload?.destinationCountry
        : "",
    yearOfBirth: groupPayload?.yearOfBirth ? groupPayload?.yearOfBirth : "",
    dateOfBirth: groupPayload?.dateOfBirth ? groupPayload?.dateOfBirth : "",
  });

  const reasons: any = [
    {
      name: "BUSINESS",
      label: "Business",
    },
    {
      name: "TOURIST",
      label: "Tourist",
    },
    {
      name: "STUDIES",
      label: "Studies",
    },

    {
      name: "MEDICAL",
      label: "Medical",
    },
  ];
  const [countries, setCountries] = useState<any[]>();

  const defaultReason = {
    value: groupPayload?.reason ? groupPayload?.reason : "",
    label: groupPayload?.reason ? groupPayload?.reason : "",
  };

  const [showAddGroupMembers, onAddGroupMembers] = useState<boolean>(false);
  const [isTravellingOptions, setIsTravellingOptions] =
    useState<boolean>(false);

  const [principle, setPrincipleInfomation] = useState<any>();
  const [groupMembers, setGroupMembers] = useState<any[]>(groupMembersSelector);

  const [groupMembersInfor, setGroupMembersInfo] = useState<any>();

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTravelInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      reason: selectedOption.name,
    }));
  };

  const handleCountriesChange = (selectedOption: any) => {
    let destination = selectedOption?.map((option) => {
      let destination = { ...option };
      destination["country"] = destination?.value;
      destination["town"] = "unknown";
      delete destination.value;
      delete destination.label;
      return destination;
    });
    setTravelInfo((previousState: any) => ({
      ...previousState,
      destinationCountry: destination,
    }));
  };

  const getTravellingOption = (): string => {
    if (travel_option == "inbound" || travel_option == "outbound") {
      return "country";
    }
    return "town";
  };

  const addGroupMembers = () => {
    onAddGroupMembers(true);
  };

  const [familyMember, setFamilyMember] = useState({
    relationship: "",
    spouseDateOfBirth: "",
    childDateOfBirth: "",
  });

  const handleInputChangeForGroupMembers = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFamilyMember((prevState) => ({ ...prevState, [name]: value }));
    let body: any = {};
    let dateOfBirth = 0;
    let monthOfBirth = 0;
    let yearOfBirth = 0;
    let age = 0;

    if (name == "spouseDateOfBirth") {
      let date = new Date(value);
      dateOfBirth = date.getDate();
      monthOfBirth = date.getMonth() + 1;
      yearOfBirth = date.getFullYear();
      let today = new Date();
      age = today.getFullYear() - yearOfBirth;
    }
    if (name == "childDateOfBirth") {
      let date = new Date(value);
      dateOfBirth = date.getDate();
      monthOfBirth = date.getMonth() + 1;
      yearOfBirth = date.getFullYear();
      let today = new Date();
      age = today.getFullYear() - yearOfBirth;
    }

    body = {
      name: null,
      idNo: null,
      passportNo: null,
      relationship: familyMember.relationship,
      dayOfBirth: dateOfBirth,
      monthOfBirth: monthOfBirth,
      yearOfBirth: yearOfBirth,
      age: age,
      email: "NA",
    };

    setGroupMembersInfo(body);
  };
  const implementTravelGroupQuoteMembers = () => {
    let travelMembers: any[] = [];
    if (familyMember.relationship == "SPOUSE") {
      if (!familyMember.spouseDateOfBirth) {
        toast.warn("Enter spouse date of brith");
        return;
      }
    }
    if (familyMember.relationship == "CHILD") {
      if (!familyMember.childDateOfBirth) {
        toast.warn("Enter child date of brith");
        return;
      }
    }
    if (groupMembersInfor) {
      travelMembers.push(groupMembersInfor);
      setGroupMembers((prevStateMember: any) => {
        let membersArray = [...prevStateMember, ...travelMembers];
        let memberArrayCopy = [...membersArray];
        let spouseElements = memberArrayCopy.filter(
          (item) => item.relationship === "SPOUSE"
        );
        let spouseArray: any[] = [];
        let children: any[] = membersArray
          ?.filter((member) => member.relationship !== "SPOUSE")
          .slice(0, 3);
        let allMemberArray: any[] = [];
        if (
          spouseElements.length > 1 ||
          groupMembersInfor?.relationship == "SPOUSE"
        ) {
          let newMemberArray = membersArray
            .reverse()
            .find((item) => item.relationship === "SPOUSE");
          spouseArray.push(newMemberArray ? newMemberArray : groupMembersInfor);
          allMemberArray = children.concat(spouseArray);
        } else {
          allMemberArray = children.concat(spouseElements);
        }
        return allMemberArray;
      });
    }
  };

  const removeGroupMember = (index: number) => {
    const newMembers = groupMembers.filter((_, i) => i !== index);
    setGroupMembers([...newMembers]);
  };

  const saveDetails = () => {
    if (!travelInfo.reason) {
      toast.warning("Please select a reason");
      document.getElementById("reason")?.focus;
      return;
    }

    if (!travelInfo.startDate) {
      toast.warning("Enter Travelling date!");
      document.getElementById("startDate")?.focus;
      return;
    }
    // let today = new Date().getTime();
    // let travellingDate = new Date(travelInfo.startDate).getTime();
    // if (travellingDate < today) {
    //   toast.warning("Enter a valid travelling date!");
    //   return;
    // }

    if (!travelInfo?.destinationCountry) {
      toast.warning("Enter Destination Country!");
      document.getElementById("destinationCountry")?.focus;
      return;
    }
    if (!travelInfo.departureCountry) {
      toast.warning("Enter Departure Country!");
      document.getElementById("departureCountry")?.focus;
      return;
    }
    let endDate = new Date(travelInfo.endDate).getTime();
    let startDate = new Date(travelInfo.startDate).getTime();
    if (endDate < startDate) {
      toast.warning("Please select a valid end date");
      return;
    }
    if (!travelInfo.yearOfBirth) {
      toast.warning("Enter principle Date of Birth!");
      document.getElementById("yearOfBirth")?.focus;
      return;
    }

    if (groupMembers.length < 1) {
      toast.warning("Please At least one group member!");
      return;
    }
   

    let dateOfBirth = 0;
    let monthOfBirth = 0;
    let yearOfBirth = 0;
    let age = 0;

    let principalDateOfBirth = new Date(travelInfo.yearOfBirth);
    dateOfBirth = principalDateOfBirth.getDate();
    monthOfBirth = principalDateOfBirth.getMonth() + 1;
    yearOfBirth = principalDateOfBirth.getFullYear();
    let today = new Date();
    age = today.getFullYear() - yearOfBirth;

    let principle = {
      dayOfBirth: dateOfBirth,
      monthOfBirth: monthOfBirth,
      relationship: "PRINCIPLE",
      yearOfBirth: yearOfBirth,
      age: age,
    };

    let date = travelInfo.startDate.replaceAll("-", "/");
    let travelDate = date.split("/").reverse().join("/");

    let travelAddedendDate = travelInfo.endDate.replaceAll("-", "/");
    let travelendDate = travelAddedendDate.split("/").reverse().join("/");

    sessionStorage.setItem("startDate", travelDate);
    sessionStorage.setItem("endDate", travelendDate);

    sessionStorage.setItem("travelInfo", JSON.stringify(travelInfo));
    sessionStorage.setItem("group_members", JSON.stringify(groupMembers));
    sessionStorage.setItem("principle", JSON.stringify(principle));
    sessionStorage.setItem("travel_type", "Family");

    if (dispatch(stepActions.onAddStep())) {
      nextStep();
      dispatch(getGroupPayloadActions.setGroupPayload(travelInfo));
      dispatch(groupMembersActions.setGroupMembersPayload(groupMembers));
    }
  };

  const getCountries = async () => {
    const result: IApiResponse = await makeGetRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/countries`
    );
    if (result.Status == 200) {
      let countries: any[] = result.Payload;
      countries.map((country) => {
        country["value"] = country?.name;
        delete country?.name;
        return country;
      });
      setCountries(countries);
    } else {
      toast.warning(result.Payload);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1 and pad with '0' if needed.
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const getMinimumBirthDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18); // Subtract 18 years from the current year

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const getMaxBirthDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear()); // Subtract 18 years from the current year

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const setIndividualScreen = () => {
    setCurrentScreen("individual");
    dispatch(travelCurrentScreenActions.setCurrentScreen("individual"));
  };

  const setGroupScreen = () => {
    setCurrentScreen("group");
    dispatch(travelCurrentScreenActions.setCurrentScreen("group"));
  };

  const setFamilyScreen = () => {
    setCurrentScreen("family");
    dispatch(travelCurrentScreenActions.setCurrentScreen("family"));
  };

  useEffect(() => {
    getCountries();
  }, [groupMembersSelector]);
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="coverType">
                  What type of cover do you want?
                </label>
                <br />
                <div className="family-type-selection">
                  <div
                    className="individual"
                    onClick={(e) => {
                      setIndividualScreen();
                    }}
                  >
                    <input
                      id="coverType"
                      name="coverType"
                      type="radio"
                      checked={individualStatus}
                      onChange={handleInputChange}
                      value={travelInfo.startDate}
                    />
                    <span className="ky-individual">Individual</span>
                  </div>
                  <div
                    className="group"
                    onClick={(e) => {
                      e.preventDefault();
                      setGroupScreen();
                    }}
                  >
                    <input
                      id="coverType"
                      name="coverType"
                      type="radio"
                      checked={groupStatus}
                      onChange={handleInputChange}
                      value={travelInfo.startDate}
                    />
                    <span className="ky-group">Group</span>
                  </div>
                  <div
                    className="group"
                    onClick={(e) => {
                      e.preventDefault();
                      setFamilyScreen();
                    }}
                  >
                    <input
                      id="coverType"
                      name="coverType"
                      type="radio"
                      checked={familyStatus}
                      onChange={handleInputChange}
                      value={travelInfo.startDate}
                    />
                    <span className="ky-group">Family</span>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="startDate">
                  When are you travelling?
                </label>
                <br />
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.startDate}
                  min={getCurrentDate()}
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="reason">
                  Please tell us your reason for travelling{" "}
                </label>
                <br />
                <Select
                  options={reasons}
                  onChange={handleChange}
                  id={"destinationCountry"}
                  className={"destinationCountry"}
                  defaultValue={defaultReason}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#F6F8FB",
                      border: state.isFocused ? "none" : "#F6F8FB",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "315px",
                      height: "47px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#00000",
                      fontWeight: "bold",
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "none",
                    }),
                  }}
                />{" "}
              </motion.div>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className={
                isTravellingOptions
                  ? "ky-input-wrapper disabled-section"
                  : "ky-input-wrapper"
              }
            >
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="endDate">
                  When are you coming back?
                </label>
                <br />
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.endDate}
                  min={getCurrentDate()}
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className={
                  getTravellingOption() !== "town"
                    ? "ky-input-container mt-4 country-travel"
                    : "ky-input-container mt-4"
                }
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="departureCountry"
                >
                  What {getTravellingOption()} are you travelling from
                  (departure)?
                </label>
                <br />
                <input
                  id="departureCountry"
                  name="departureCountry"
                  type="text"
                  className={
                    getTravellingOption() !== "town" ? "country-travel" : ""
                  }
                  onChange={handleInputChange}
                  value={travelInfo.departureCountry}
                  placeholder="Type location"
                ></input>
              </motion.div>
            </div>
          </div>
          {travel_option == "outbound" && (
            <div className="col-md-5">
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container mt-4"
                >
                  <label className="text-left-label mb-2" htmlFor="reason">
                    What is the final country you are travelling to (arrival)?{" "}
                  </label>
                  <br />
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={countries}
                    onChange={handleCountriesChange}
                    // value={selectedCountries}
                    id={"destinationCountry"}
                    className={"destinationCountry"}
                    // defaultValue={loadDestinationCountries()}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#F6F8FB",
                        border: state.isFocused ? "none" : "#F6F8FB",
                      }),

                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#00000",
                        fontWeight: "bold",
                      }),
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "none",
                      }),
                    }}
                  />{" "}
                </motion.div>
              </div>
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="yearOfBirth">
                  When is your birth date?{" "}
                </label>
                <br />
                <input
                  id="yearOfBirth"
                  name="yearOfBirth"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.yearOfBirth}
                  max={getMinimumBirthDate()}
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2">
                  Please add family members{" "}
                </label>
                <br />
                <div className="group-countries">
                  <div className="add-member" onClick={() => addGroupMembers()}>
                    <IoIcons.IoIosAdd />
                    <span className="add-member-text">Add New Member</span>
                  </div>
                  <div
                    className={
                      showAddGroupMembers
                        ? "ky-input-wrapper"
                        : "ky-input-wrapper disabled-section"
                    }
                  >
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                      className="ky-input-container mt-4"
                    >
                      <label
                        className="text-left-label mb-2"
                        htmlFor="dateOfBirth"
                      >
                        Relationship{" "}
                      </label>
                      <br />

                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleInputChangeForGroupMembers}
                        value={familyMember.relationship}
                        name="relationship"
                        id="relationship"
                      >
                        <option></option>
                        <option value="SPOUSE">SPOUSE</option>
                        <option value="CHILD">CHILD</option>
                      </select>
                    </motion.div>
                  </div>
                  <div
                    className={
                      showAddGroupMembers
                        ? "ky-input-wrapper"
                        : "ky-input-wrapper disabled-section"
                    }
                  >
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                      className="ky-input-container mt-4"
                    >
                      <label
                        className="text-left-label mb-2"
                        htmlFor="dateOfBirth"
                      >
                        Birth date?{" "}
                      </label>
                      <br />
                      {familyMember.relationship == "SPOUSE" && (
                        <motion.div
                          animate={{ scale: [-0.7, 1] }}
                          transition={{ duration: 1 }}
                        >
                          <input
                            id="spouseDateOfBirth"
                            name="spouseDateOfBirth"
                            type="date"
                            onChange={handleInputChangeForGroupMembers}
                            max={getMinimumBirthDate()}
                            value={familyMember.spouseDateOfBirth}
                          ></input>
                        </motion.div>
                      )}
                      {familyMember.relationship == "CHILD" && (
                        <motion.div
                          animate={{ scale: [-0.7, 1] }}
                          transition={{ duration: 1 }}
                        >
                          <input
                            id="childDateOfBirth"
                            name="childDateOfBirth"
                            type="date"
                            onChange={handleInputChangeForGroupMembers}
                            min={getMinimumBirthDate()}
                            max={getMaxBirthDate()}
                            value={familyMember.childDateOfBirth}
                          ></input>
                        </motion.div>
                      )}
                    </motion.div>
                  </div>

                  <div
                    className={
                      showAddGroupMembers
                        ? "ky-input-wrapper"
                        : "ky-input-wrapper disabled-section"
                    }
                  >
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                      className="ky-input-container mt-4"
                    >
                      <label
                        className="text-left-label mb-2"
                        htmlFor="startDate"
                      >
                        Gender?
                      </label>
                      <br />
                      <div className="ky-input-wrapper-type">
                        <div className="member-radio gender">
                          <input id="gender" name="gender" type="radio" />
                          <span className="ky-individual">Male</span>
                        </div>
                        <div className="group">
                          <input id="gender" name="gender" type="radio" />
                          <span className="ky-group">Female</span>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                  <h4
                    className={
                      showAddGroupMembers ? "add" : "add disabled-section"
                    }
                    onClick={() => implementTravelGroupQuoteMembers()}
                  >
                    ADD
                  </h4>
                  <hr />
                  <div className="members-container">
                    {groupMembers.length > 0 &&
                      groupMembers.map((item, index) => {
                        return (
                          <div className="group-members" key={index}>
                            <div className="group-member-icons">
                              <div className="avatar-image">
                                <img
                                  src={avatar}
                                  alt="avatar"
                                  style={{ height: "20px", marginLeft: "1rem" }}
                                />
                              </div>
                              <div
                                className="cancel-icon"
                                onClick={() => removeGroupMember(index)}
                              >
                                <img
                                  src={GiCancel}
                                  alt="cancel"
                                  style={{ height: "20px" }}
                                />
                              </div>
                            </div>
                            <div className="group-members-content ms-3 mb-2">
                              <div className="group-members-content-title">
                                {item.relationship}
                              </div>
                              <div className="group-members-content-age">
                                {item.age} Years
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <div className="ky-inputs-container-1">
        <div className="mt-2 pointer-button">
          <div className="previous">
            <span onClick={() => previousStep()}>
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <button className="next-btn" onClick={() => saveDetails()}>
            {" "}
            <span>
              Next{" "}
              <span className="GrIcon ms-3">
                <GrIcons.GrFormNextLink />
              </span>
            </span>{" "}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default FamilyDetails;
