import React, { useState } from "react";
import { sendPasswordResetEmail } from "../../../firebase";
import * as MdIcons from "react-icons/md";
import { isFirefox } from "react-device-detect";
import { motion } from "framer-motion";
import { variants } from "../../shared/variants";

const email = require("../../../assets/images/email.png");


const Reset = ({ setCurrentScreen }: any) => {
  const [userInfo, setUserInfo] = useState({
    email: "",
  });

  const [emailValid, setEmailValid] = useState(true);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    }

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = () => {
    if (!validateEmail(userInfo.email)) {
      setEmailValid(false);
      return;
    }

    setEmailValid(true);

    sendPasswordResetEmail(userInfo.email);
  };

  const validateEmail = (email: any) => {
    //eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      className="steps-conatiner step-1-container"
      style={{marginTop: "200px"}}
    >
      <div className="auth-login mt-5" >
      <h1 className="auth-login-description">
        {" "}
        Reset your<span className="login-info"> password</span>
      </h1>
      <div className="auth-input-container">
        <img src={email} alt="email icon" />
        <div className="label">Your email address</div>
        <input
          type="text"
          placeholder="email"
          name="email"
          value={userInfo.email}
          onChange={handleInputChange}
        />
        {!emailValid && (
          <div className="error-msg">
            <MdIcons.MdError />
            <span>Please enter a valid email</span>
          </div>
        )}
      </div>
      {isFirefox && <div className="spacing-wrapper"></div>}
      <div className="d-flex justify-content-center mt-3">
        <button onClick={() => handleClick()} className="pointer-btn">Reset your password</button>
      </div>
      <p className="sign-up-text">
        Already have an account?{" "}
        <span onClick={(e) => setCurrentScreen("login")}>Sign in</span>
      </p>
      {isFirefox && <div className="spacing-wrapper"></div>}
      </div>
    
    </motion.div>
  );
};

export default Reset;
