import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { variants } from "../../shared/variants";
import { NumericFormat } from "react-number-format";
import { useWizard } from "react-use-wizard";
import { useDispatch } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { toast } from "react-toastify";
import { makePostRequest } from "../../services/apiHandler";
import { stepActions } from "../../store/Steps";
import * as GrIcons from "react-icons/gr";
import Loader from "../../shared/loader/Loader";

function Promotions({ setCurrentScreen }: any) {
  const { previousStep, nextStep } = useWizard();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [processingPromo, setProcessingPromo] = useState(false);
  const [quoteInfor, setQuoteInfor] = useState({
    code: "",
    discount: 0,
    payable: 0,
    qid: "",
    product: "",
    isFinanced: false,
    endtimestamp: 0,
    _date: {},
    _timestamp: 0,
    _id: "",
  });
  const mixpanel = useMixpanel();

  let quote = JSON.parse(sessionStorage.getItem("quote")!);

  const [promoInfo, setPromoInfor] = useState({
    code: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPromoInfor((prevState) => ({
      code: value,
    }));
  };

  const calculatePromo = async () => {
    if (!promoInfo.code) {
      toast.error("No Promo code added");
      return;
    }
    setProcessingPromo(true);
    let body = {
      code: promoInfo.code,
      id: quote?._id,
      insurer: quote?.insurer,
      payable: quote?.amount,
      product: "insurance_travel",
    };
    const apiResponse: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_UPXSELL_API_URL}/getpromo`,
      body,
      {
        SKEY: sessionStorage.getItem("SKEY")!,
        ModuleID: process.env.REACT_APP_MODULE_ID,
        apiKey: process.env.REACT_APP_MODULE_ID,
      }
    );
    try {
      setProcessingPromo(false);
      if (apiResponse.Status == 200) {
        toast.success(apiResponse.Message);
        setQuoteInfor(apiResponse.Payload);
        quote.amount = quoteInfor.discount
      } else if (apiResponse.Status == 543) {
      } else {
        toast.error(apiResponse.Payload);
      }
    } catch (error: any) {
      setProcessingPromo(false);
      toast.error(error);
    }
  };

  const proceed = () => {
    sessionStorage.setItem("quote", JSON.stringify(quote))
    if (quote.amount < 10000) {
      sessionStorage.setItem("payment-screen", "payment-method");
      setCurrentScreen("payment-method");
    } else {
      sessionStorage.setItem("payment-screen", "payment-opt");
      setCurrentScreen("payment-opt");
    }
  };

  useEffect(() => {
    if (mixpanel.config.token) {
    }
    mixpanel.track("PageView", {
      pageName: "Travel|Promotions",
    });
    // refreshToken();
  }, []);

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
      className="steps-conatiner2"
    >
      <div className="personal-information">
        <div className="reg-title text-center">
          <span style={{ color: "#30C2DB" }}>Get Your Discount</span>
        </div>
        <div className="row ">
          <div className="col-md-4 promotion">
            <div className="promo-container mt-4">
              <div className="promo-title">GOT A PROMO CODE?</div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="promotion-code"
                  placeholder="Type Promocode"
                  onChange={handleInputChange}
                  name={promoInfo.code}
                />
                {processingPromo ? (
                  <Loader />
                ) : (
                  <span
                    className="input-group-text promo-action-btn"
                    id="inputGroup-sizing-default"
                    onClick={() => calculatePromo()}
                  >
                    Apply
                  </span>
                )}
              </div>
            </div>
            <div className="promo-container mt-4">
              <div className="promo-breakdown">
                <div className="premium-amount-key">Sub total</div>
                <div className="premium-amount-value">
                  <NumericFormat
                    value={quote?.premium?.payable?.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"KES "}
                  />
                </div>
              </div>
              <div className="promo-breakdown">
                <div className="premium-amount-key">Promo Discount</div>
                <div className="premium-amount-value">
                  {quoteInfor?.discount ? (
                    <NumericFormat
                      value={
                        quoteInfor?.discount
                          ? quoteInfor?.discount?.toFixed(2)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"KES "}
                    />
                  ) : (
                    0
                  )}
                </div>
              </div>
              <div className="promo-breakdown grand-total">
                <div className="premium-amount">Grand Total</div>
                <div className="premium-amount">
                  {" "}
                  <NumericFormat
                    value={
                      quoteInfor?.payable
                        ? quoteInfor?.payable?.toFixed(2)
                        : quote?.amount?.toFixed(2)
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"KES "}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-around align-items-center ms-3 me-2 mt-5">
        <div onClick={() => proceed()}>
          <button className="next-btn">
            {" "}
            <span>
              Proceed To Pay{" "}
            </span>{" "}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default Promotions;
