import React, { useState } from "react";
import { registerWithEmailAndPassword } from "../../../firebase.js";
import * as MdIcons from "react-icons/md";
import { isFirefox } from "react-device-detect";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import {
  makePostRequest,
  makePostRequestWithLatestHeaders,
} from "../../services/apiHandler";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { profileActions } from "../../store/UserProfileData";

const email = require("../../../assets/images/lock.png");
const password = require("../../../assets/images/lock.png");

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const Signup = ({ setCurrentScreen }: any) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const profileSelector = useSelector((state: any) => state.profile.profile);

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    password2: "",
  });
  const [emailValid, setEmailValid] = useState(true);
  const [passValid, setPassValid] = useState(true);
  const [passValid2, setPass2Valid] = useState(true);
  const [userLoading, setUserLoading] = useState(false);

  const onSuccessLoginOrSignUp = () => {
    let fromMyPol = JSON.parse(sessionStorage.getItem("fromMyPol")!) == true;
    if (fromMyPol) {
      sessionStorage.setItem("fromMyPol", "false");
      navigate("/policies");
    } else {
      navigate(-1);
    }
  };

  const handleClick = () => {
    if (!validateEmail(userInfo.email)) {
      setEmailValid(false);
      return;
    }

    if (userInfo.password.length < 8) {
      setPassValid(false);
      return;
    }

    if (userInfo.password2.length < 8) {
      setPass2Valid(false);
      return;
    }

    if (userInfo.password !== userInfo.password2) {
      toast.warn("Passwords should match!");
      return;
    }

    setEmailValid(true);

    setUserLoading(true);
    registerWithEmailAndPassword(userInfo.email, userInfo.password).then(
      async (res: any) => {
        try {
          if (res.hasOwnProperty("additionalUserInfo")) {
            let user = res.user._delegate;
            let userName = user.email.split("@")[0];
            sessionStorage.setItem("uid", user.uid);
            sessionStorage.setItem("SKEY", user.accessToken);
            sessionStorage.setItem("email", user.email);
            sessionStorage.setItem("userName", userName);
            const result: IApiResponse = await makePostRequestWithLatestHeaders(
              `${process.env.REACT_APP_CORE_API_URL}/saveNewUser`,
              {
                userName: userName,
                email: user.email,
                firstName: userName,
                feduid: user.uid,
                lastName: "",
                phoneNumber: user?.phoneNumber || "",
              },
              {
                SKEY: sessionStorage.getItem("SKEY"),
              }
            );

            if (result.Status === 200 || result.Status === 201) {
              sessionStorage.setItem("uid", user.uid);
              sessionStorage.setItem("SKEY", user.accessToken);
              sessionStorage.setItem("email", user.email);
              sessionStorage.setItem("userName", userName);
              setUserLoading(false);
              sessionStorage.setItem("loggedIn", "true");
              toast.success("Signed up successfully!");

              let userPrfile = {
                ...profileSelector,
                name: userName,
                email: user.email,
                phone: user?.phoneNumber || "",
                feduid: user.uid,
              };
              dispatch(profileActions.setUserProfile(userPrfile));
              if (mixpanel.config.token) {
                mixpanel.identify(mixpanel.get_distinct_id());
                mixpanel.people.set({
                  email: userPrfile.email,
                  feduid: userPrfile.feduid,
                  name: userPrfile.name,
                  phone: userPrfile.phone,
                });
                mixpanel.people.set("$email", userPrfile.email);
                mixpanel.register({
                  name: userPrfile.name,
                  email: userPrfile.email,
                  phone: userPrfile.phoneNumber,
                });
              }

              onSuccessLoginOrSignUp();
            } else if (
              result.Status === 502 ||
              result.Status === 501 ||
              result.Status === 500
            ) {
              toast.error(
                "Something went wrong. Please try signing up in again!"
              );
              setUserLoading(false);
              return;
            } else {
              toast.error(
                "Something went wrong. Please try signing up in again!"
              );
              setUserLoading(false);
              return;
            }
          } else {
            setUserLoading(false);
          }
        } catch (err) {
          setUserLoading(false);
        }
      }
    );
  };

  const validateEmail = (email: any) => {
    //eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    }

    if (name === "password") {
      if (value.length < 8) {
        setPassValid(false);
      } else {
        setPassValid(true);
      }
    }

    if (name === "password2") {
      if (value.length < 8) {
        setPass2Valid(false);
      } else {
        setPass2Valid(true);
      }
    }

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <motion.div
      className="steps-conatiner2 mb-5"
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      <br />
      <div className="auth-login mt-5">
        <h1 className="auth-login-description">
          Create your <span className="login-info">account</span>{" "}
        </h1>
        <div className="auth-area-wrapper">
          <div className="auth-input-container">
            <img src={email} alt="email icon" />
            <div className="label">Your email address</div>
            <input
              type="text"
              placeholder="email"
              name="email"
              value={userInfo.email}
              onChange={handleInputChange}
            />
            {!emailValid && (
              <div className="error-msg">
                <MdIcons.MdError />
                <span>Please enter a valid email</span>
              </div>
            )}
          </div>
          <div className="auth-input-container">
            <img src={password} alt="password icon" />
            <div className="label">Password</div>
            <input
              type="password"
              placeholder="password"
              name="password"
              value={userInfo.password}
              onChange={handleInputChange}
            />
            {!passValid && (
              <div className="error-msg">
                <MdIcons.MdError />
                <span>Password must be more than 8 characters</span>
              </div>
            )}
          </div>
          <div className="auth-input-container">
            <img src={password} alt="password icon" />
            <div className="label">Confirm Password</div>
            <input
              type="password"
              placeholder="password"
              name="password2"
              value={userInfo.password2}
              onChange={handleInputChange}
            />
            {!passValid2 && (
              <div className="error-msg">
                <MdIcons.MdError />
                <span>Password must be more than 8 characters</span>
              </div>
            )}
          </div>
          <br />
          {isFirefox && <div className="spacing-wrapper"></div>}
          {userLoading && (
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <button className="pointer-btn" onClick={() => handleClick()}>
              Sign Up
            </button>
          </div>
          <p className="sign-up-text text-center">
            Already have an account?{" "}
            <span onClick={(e) => setCurrentScreen("login")}>Sign in</span>
          </p>
          {isFirefox && <div className="spacing-wrapper"></div>}
          <br />
        </div>
      </div>
    </motion.div>
  );
};

export default Signup;
