import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { variants } from "../../shared/variants";
import { useDispatch, useSelector } from "react-redux";
import { makePostRequest } from "../../services/apiHandler";
import { toast } from "react-toastify";
import { stepActions } from "../../store/Steps";
import { getGroupPayloadActions } from "../../store/GroupPayload";
import { useWizard } from "react-use-wizard";

const GiCancel = require("../../../assets/images/GiCancel.png");
const avatar = require("../../../assets/images/user2.png");

function WhatsppGroupMembers() {
  const groupMembersSelector = useSelector((state: any) => state.groupMembers);
  const [groupMembers, setGroupMembers] = useState<any>(groupMembersSelector);
  const [groupMembersInfor, setGroupMembersInfo] = useState<any>();
  const { goToStep } = useWizard();
  
  const [travelInfo, setTravelInfo] = useState<any>();
  const dispatch = useDispatch();
  const getTravelGroupPayload = async () => {
    const location = window.location.search;
    const groupid = new URLSearchParams(location).get("groupid");
    try {
      const apiResponse: IApiResponse = await makePostRequest(
        `${process.env.REACT_APP_TRAVEL_API_URL}/listQuoteRequests`,
        { _id: groupid }
      );

      if (apiResponse.Status == 200) {
        setTravelInfo(apiResponse.Payload);
      } else {
        toast.error(apiResponse.Payload);
      }
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const getMinimumBirthDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18); // Subtract 18 years from the current year

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const removeGroupMember = (index: number) => {
    const newMembers = groupMembers.filter((_, i) => i !== index);
    setGroupMembers([...newMembers]);
  };

  const implementTravelGroupQuoteMembers = () => {
    let travelMembers: any[] = [];
    if (groupMembersInfor) {
      travelMembers.push(groupMembersInfor);
      setGroupMembers((prevStateMember: any) => [
        ...prevStateMember,
        ...travelMembers,
      ]);
    }
  };

  const handleInputChangeForGroupMembers = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let dateOfBirth = 0;
    let monthOfBirth = 0;
    let yearOfBirth = 0;
    let age = 0;

    let date = new Date(value);
    dateOfBirth = date.getDate();
    monthOfBirth = date.getMonth() + 1;
    yearOfBirth = date.getFullYear();
    let today = new Date();
    age = today.getFullYear() - yearOfBirth;

    let body: any = {
      name: null,
      idNo: null,
      passportNo: null,
      relationship: "GROUP",
      dayOfBirth: dateOfBirth,
      monthOfBirth: monthOfBirth,
      yearOfBirth: yearOfBirth,
      age: age,
      email: "NA",
    };
    setGroupMembersInfo(body);
  };

  const saveMemberDetails = () => {
    if (groupMembers.length > 0) {
      let travlData = travelInfo;
      let principalArray: any[] = [...(travlData?.group ?? [])];
      let group = principalArray?.concat(groupMembers);
      let uploadGroupInfoData = {
        ...travlData,
        group: group,
      };
      delete uploadGroupInfoData?._id;
      delete uploadGroupInfoData?._date;
      delete uploadGroupInfoData?._timestamp;
      delete uploadGroupInfoData?.principle;
      if (dispatch(stepActions.updateStepTo(2))) {
        goToStep(2);
        dispatch(getGroupPayloadActions.setGroupPayload(uploadGroupInfoData));
        // dispatch(groupMembersActions.setGroupMembersPayload(groupMembers));
        sessionStorage.setItem(
          "whatsppPaload",
          JSON.stringify(uploadGroupInfoData)
        );
      }
    } else {
      toast.warn("Add group member to proceed");
    }
  };

  useEffect(() => {
    getTravelGroupPayload();
  }, []);

  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <div className="row">
        <div className="col-md-5">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container"
            >
              <div className="group-countries">
                <div className={"ky-input-wrapper"}>
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="dateOfBirth"
                    >
                      Birth date?{" "}
                    </label>
                    <br />
                    <input
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      onChange={handleInputChangeForGroupMembers}
                      max={getMinimumBirthDate()}
                      // value={groupMembersInfor.dateOfBirth}
                    ></input>
                  </motion.div>
                </div>
                <div className={"ky-input-wrapper"}>
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="startDate">
                      Gender?
                    </label>
                    <br />
                    <div className="ky-input-wrapper-type">
                      <div className="individual gender">
                        <input id="gender" name="gender" type="radio" />
                        <span className="ky-individual">Male</span>
                      </div>
                      <div className="group">
                        <input id="gender" name="gender" type="radio" />
                        <span className="ky-group">Female</span>
                      </div>
                    </div>
                  </motion.div>
                </div>
                <h4
                  className={"add"}
                  onClick={() => implementTravelGroupQuoteMembers()}
                >
                  ADD
                </h4>
                <hr />
                <div className="members-container">
                  {groupMembers.length > 0 &&
                    groupMembers.map((item, index) => {
                      return (
                        <div className="group-members" key={index}>
                          <div className="group-member-icons">
                            <div className="avatar-image">
                              <img
                                src={avatar}
                                alt="avatar"
                                style={{ height: "20px", marginLeft: "1rem" }}
                              />
                            </div>
                            <div
                              className="cancel-icon"
                              onClick={() => removeGroupMember(index)}
                            >
                              <img
                                src={GiCancel}
                                alt="cancel"
                                style={{ height: "20px" }}
                              />
                            </div>
                          </div>
                          <div className="group-members-content ms-3 mb-2">
                            <div className="group-members-content-title">
                              {item.relationship}
                            </div>
                            <div className="group-members-content-age">
                              {item.age} Years
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="button d-flex justify-content-end align-items-end flex-end">
          <button className="next-btn" onClick={saveMemberDetails}>
            Proceed
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default WhatsppGroupMembers;
